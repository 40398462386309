import React, {useState} from 'react'
import Spinner from '../Spinner/Spinner'
import StaticSiteDTO, {IStaticSite} from '../../DTO/StaticSiteDTO'
import StaticSiteParagraphDTO from '../../DTO/StaticSiteParagraphDTO'
import Paragraph from './Paragraph'
import { Getter, Setter } from '../../services/ComService'
import { Settings, Edit, Trash, ArrowUpCircle, ArrowDownCircle, Eye, Image } from 'react-feather'
import InputA from '../InputA/InputA'
import mainservice from '../../services/MainService'
import { GetAllParagraphTypes, GetParagraphPropTypes, ParagraphPropType, PropType } from './ParagraphTypes'
import JoditReact from 'jodit-react'
import FileList, {FileListMode} from '../MediaManager/FileList'
// import 'jodit/build/jodit.min.css'
import './StaticPageAdmin.scss'

enum Status {
  loading,
  ready,
  empty,
  error,
  standard,
  edit,
  changed,
}

type Props = {

}

type State = {
  status: Status
}

export default class StaticPageAdmin extends React.Component<Props, State> {
  pageList: StaticSiteDTO[] = []
  pageId: number = -1
  page: StaticSiteDTO | null = null
  constructor(props: Props) {
    super(props)
    this.state = {
      status: Status.loading
    }
  }

  componentDidMount() {
    this.getData()
  }

  async getData() {
    console.log('getData!')
    this.pageId = mainservice.nav.getVal('id') as unknown as number
    console.log('pageId', this.pageId)
    this.setState({status: Status.loading})
    if (this.pageId > -1) {
      const raw = await Setter('staticsite/getbyid', {id: this.pageId})
      if (!raw) { return }
      const pdata = raw as {status: string, result: IStaticSite}
      if (pdata.status === 'okay') {
        this.page = new StaticSiteDTO(pdata.result)
        this.setState({status: Status.ready})
        return
      }
      this.setState({status: Status.error})
      return
    }
    const data: {status: string, result: IStaticSite[]} = await Getter('staticsite/list')
    if (data.status === 'okay') {
      this.pageList = data.result.map((p) => new StaticSiteDTO(p))
      if (this.pageList.length === 0) {
        this.setState({status: Status.empty})
      } else {
        this.setState({status: Status.ready})
      }
      return
    }
    this.setState({status: Status.error})
  }

  render() {
    const content = () => {
      switch(this.state.status) {
        case Status.loading:
          return <Spinner />
        case Status.ready:
          return <StaticPageList
            siteList={this.pageList}
            cb={ () => this.getData() }
          />
        case Status.empty:
          return <div className='notice-box'>Noch keine Seiten angelegt</div>
        case Status.error:
          return <div className='error-box'>Fehler beim Laden</div>
      }
    }
    if (this.pageId > -1) {
      return <>
        <button
          onClick={() => {
            mainservice.navTo([['view', 'StaticPageAdmin']])
            this.getData()
          }}
        >Zurück</button>
        <StaticPageDesigner
          cb={() => {
            console.log('-RELOAD-')
            this.getData()
          }}
          page={this.page}
        />
      </>
    }
    return <div className='StaticPageAdmin'>
      <button
        className='secondary-button'
        onClick={() => {
          mainservice.navTo([['view', 'adminlogin']])
        }}
      >&larr; Dashboard</button>
      <h2>Statische Seiten</h2>
      <AddNew cb={() => {
        console.log('-RELOAD-')
        this.getData()
      }} />
      { content() }
    </div>
  }
}

function StaticPageList (props: {siteList: StaticSiteDTO[], cb: () => void}) {
  return <div className='siteList'>
    {
      props.siteList.map((s, i) => <StaticPageA
        key={`site-${s.id}`}
        page={s}
        cb={() => {
          console.log('staticPageList says - new!')
          props.cb()
        }}
      />)
    }
  </div>
}

function StaticPageA(props: {
    page: StaticSiteDTO,
    cb: () => void,
    status?: Status
}) {
  const startStatus = (props.status === undefined) ? Status.standard : props.status
  const [status, setStatus] = useState(startStatus)
  const render = () => {
    switch(status) {
      case Status.standard:
        return <div className='w3-border'>
          <div>url: {props.page.url}</div>
          <div>name: {props.page.name}</div>
          <button
            className='secondary-button'
            onClick={() => {
              setStatus(Status.edit)
            }}
          >
            <Settings />
          </button>
          <button
            className='secondary-button'
            onClick={() => {
              mainservice.navTo([['view', 'StaticPageAdmin'], ['id', props.page.id]])
              props.cb()
            }}
          >
            <Edit />
          </button>
        </div>
      case Status.edit:
        return <>
          url (für Startseite <i>LANDING</i> eintragen!):
          <InputA
            value={props.page.url}
            returnVal={(rv) => {
              props.page.setUrl(rv)
            }}
          />
          name:
          <InputA
            value={props.page.name}
            returnVal={(rv) => {
              props.page.setName(rv)
            }}
          />
          <button
            className='good-button'
            onClick={async () => {
              await props.page.saveToServer()
              props.cb()
            }}
          >Speichern</button>
          <button
            className='secondary-button'
            onClick={() => {
              props.cb()
            }}
          >Abbrechen</button>
          <button
            className='danger-button'
            onClick={async () => {
              if ( window.confirm('Seite wirklich unwiederbringlich löschen?') ) {
                await props.page.remove()
                props.cb()
              }
            }}
          ><Trash /></button>
        </>
    }
  }
  return <div className='static-page static-page-admin'>
    { render() }
  </div>
}

function AddNew(props: {cb: () => void}) {
  const [status, setStatus] = useState(Status.standard)
  let page = new StaticSiteDTO({id: -1})
  const result = () => {
    switch(status) {
      case Status.standard:
        return <button
            className='primary-button'
            onClick={() => {
              page = new StaticSiteDTO({id: -1})
              setStatus(Status.ready)
            }}
          >+</button>
      case Status.ready:
        return <StaticPageA
            page={page}
            cb={() => {
              setStatus(Status.standard)
              // Tell parent, that new content available
              console.log('add new say new')
              props.cb()
            }}
            status={Status.edit}
          />
    }
  }
  return <div className='add-new'>
    { result() }
  </div>
}

function StaticPageDesigner(props: {
  page: StaticSiteDTO | null,
  cb: () => void,
}) {
  const [activeParagraphId, setActiveParagraphId] = useState(-1)
  if (props.page === null) { return null }
  const page: StaticSiteDTO = props.page
  const paragraphs = page.getParagraphs()
  return <div className='StaticPageDesigner'>
    Static Page Designer
    <AddParagraphButton
      position={ 0 }
      page={ props.page }
      cb={ () => {props.cb()} }
    />
    {
      paragraphs.map((p, i) => {
        const position = (i + 1) * 10
        return <div
          key={`paragraph-block-${p.id}`}
        >
          <ParagraphHeader
            cb={ (msg: Status) => {
              if (msg === Status.edit) {
                setActiveParagraphId(p.id)
                props.cb()
              } else if (msg === Status.standard) {
                setActiveParagraphId(-1)
                props.cb()
              } else {
                props.cb()
              }
            }}
            paragraph={ p }
            active={p.id === activeParagraphId}
          />
          <ParagraphDesigner
            key={ `paragraphDesigner-${p.id}` }
            cb={ () => { props.cb() } }
            page={ page }
            paragraph={ p }
            position={ position }
            active={p.id === activeParagraphId}
          />
          <AddParagraphButton
            key={ `addbutton-${i}` }
            position={ position + 5 }
            page={ page }
            cb={ () => { props.cb() } }
          />
        </div>
      })
    }
  </div>
}

function AddParagraphButton(props: {
  position: number,
  page: StaticSiteDTO,
  cb: () => void,
}) {
  const [status, setStatus] = useState(Status.standard)
  const paragraphTypes: {name: string, key: string}[] = (GetAllParagraphTypes() as {name: string, key: string}[]).concat([{
    name: 'Abbrechen',
    key: ''
  }])
  const render = () => {
    switch(status) {
      case Status.standard:
        return <div className='add-paragraph-button-hover-target'>
            <button
              onClick={() => {
                setStatus(Status.edit)
              }}
            >+ Paragraph hinzufügen</button>
          </div>
      case Status.edit:
        return <div>
            { paragraphTypes.map((p) => <button
                key={`paragraphtype-${p.key}`}
                onClick={async () => {
                  if (p.key) {
                    setStatus(Status.loading)
                    await props.page.newParagraph({
                      position: props.position,
                      type: p.key
                    })
                    props.cb()
                  }
                  setStatus(Status.standard)
                }}
              >
                { p.name }
              </button>)
            }
          </div>
      case Status.loading:
        return <div>
            <Spinner mini={true} />
          </div>
    }
  }
  return <div className={`add-paragraph-button`}>
      { render() }
    </div>
}

function ParagraphHeader(props: {
  cb: (msg: Status) => void,
  paragraph: StaticSiteParagraphDTO,
  active: boolean
}) {
  const p = props.paragraph
  return <div className='ParagraphHeader notice-box'>
      <button disabled>{p.sortNumber * 0.1}</button>
      <button
        onClick={async () => {
          await p.setPosition(p.sortNumber - 15)
          props.cb(Status.changed)
        }}
      ><ArrowUpCircle /></button>
      <button
        onClick={async () => {
          await p.setPosition(p.sortNumber + 15)
          props.cb(Status.changed)
        }}
      ><ArrowDownCircle /></button>
      <button
        onClick={async () => {
          if (window.confirm('Absatz unwiederbringlich löschen?')) {
            await p.remove()
            props.cb(Status.changed)
          }
        }}
      ><Trash /></button>
      {
        props.active &&
        <button
        onClick={() => {
          props.cb(Status.standard)
        }}
        ><Eye /></button>
      }
      {
        !props.active &&
        <button
        onClick={() => {
          props.cb(Status.edit)
        }}
        ><Edit /></button>
      }
    </div>
}

function ParagraphDesigner(props: {
  cb: () => void,
  page: StaticSiteDTO,
  paragraph: StaticSiteParagraphDTO,
  position: number,
  active: boolean
}) {
  const paragraphType: string = props.paragraph.getPropVal1('paragraphType', '')
  const propTypes = GetParagraphPropTypes(paragraphType)
  if (!props.active) {
    return <Paragraph paragraph={props.paragraph} />
  }
  return <div className='ParagraphDesigner w3-border w3-shadow w3-padding w3-margin'>
      {
        propTypes.map((t, i) => {
          return <ParagraphPropEditor
              key={`${i}-${t.key1}-${t.key2}`}
              propType={t}
              paragraph={props.paragraph}
              rerender={() => {console.log('do rerender')}}
            />
        })
      }
    </div>
}

function ParagraphPropEditor(props: {
  propType: ParagraphPropType,
  paragraph: StaticSiteParagraphDTO,
  rerender: () => void
}) {
  const pt = props.propType
  const [status, setStatus] = useState(Status.edit)
  const [content, setContent] = useState(props.paragraph.getPropVal1(pt.key1, pt.key2))
  switch(pt.kind) {
    case PropType.content:
      return <div>
        {pt.name}:
        <JoditReact
          onChange={async (c) => {
            setContent(c)
            setStatus(Status.changed)
          }}
          value={content}
        />
        {
          status === Status.changed &&
          <button
            onClick={
              async () => {
                console.log('Save content', content)
                await props.paragraph.setPropVal1(pt.key1, pt.key2, content)
                setStatus(Status.edit)
                props.rerender()
              }
            }
          >Speichern</button>
        }
      </div>
    case PropType.text:
      return <div>
        {pt.name}:
        <InputA
          value={content}
          returnVal={async (rv) => {
            setContent(rv)
            await props.paragraph.setPropVal1(pt.key1, pt.key2, rv)
            props.rerender()
          }}
        />
      </div>
    case PropType.picture:
      if (status === Status.changed) {
        return <div>
            <FileList
              mode={FileListMode.selectPicture}
              usePicture={async (id: number) => {
                await props.paragraph.setPicture(pt.key1, pt.key2, id)
                setStatus(Status.edit)
              }}
            />
          </div>
      } else {
        return <button
            onClick={() => {
              setStatus(Status.changed)
            }}
          ><Image /></button>
      }
    case PropType.select:
      return <div>
          {pt.name}:
          {
            pt.options?.map((o) => <button
                key={o.value}
                className={`${(o.value === content) ? 'primary-button' : 'secondary-button'}`}
                onClick={async () => {
                  await props.paragraph.setPropVal1(pt.key1, pt.key2, o.value)
                  setContent(o.value)
                  props.rerender()
                }}
              >{o.name}</button>)
          }
        </div>
  }
  return null
}
