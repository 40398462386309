import React from 'react'
import './CollectibleItemA.scss'
import CollectibleDTO, {CollectiblesDTO} from '../../DTO/CollectibleDTO'
import { Getter } from '../../services/ComService'
import CollectibleItemC from './CollectibleItemC'
import mainservice from '../../services/MainService'
import User from '../User/User'

type State = {
  id: string,
  collectible: CollectibleDTO | null,
  showLogin: boolean
}

type Props = {
}

export default class CollectibleSingle extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    console.log('collectibleSingleGetVal')
    this.state = {
      id: mainservice.nav.getVal('uid') as string,
      collectible: null,
      showLogin: false
    }
  }

  componentDidMount() {
    this.getData()
    mainservice.registerToBroadcast('collectibleSingle', (key: string, value: any) => {
      switch (key) {
        case 'showLogin':
          this.setState({showLogin: true})
          break
        case 'loginsuccess':
          this.setState({showLogin: false})
          break
      }
    }, this)
  }

  componentWillUnmount() {
    mainservice.unregisterBroadcast('collectibleSingle')
  }

  async getData() {
    console.log('COLLECTABLE SINGLE getData', this.state.id)
    const collectibleRaw = await Getter(`collectable/${this.state.id}`)
    const collectible = new CollectibleDTO(collectibleRaw.collectable)
    this.setState({collectible: collectible})
  }
  render() {
    if (!this.state.collectible) {
      return null
    }
    if (this.state.showLogin) {
      return <User />
    }
    return <div className='lightbox'>
      <div className='lightboxItem'>
        <CollectibleItemC
          className={'collectible-box'}
          collectible={this.state.collectible}
          singleMode={true}
        />
      </div>
    </div>
  }
}
