import React from 'react'
import SessionDTO, { CoachRowData, SessionItem } from '../../DTO/SessionDTO'
import Spinner from '../Spinner/Spinner'
import InputA from '../InputA/InputA'
import Evaluation1Effective, { EffectiveRatingColor, EffectiveRating, PointToRatingString } from '../../Models/Evaluation1/Effective'
// import Evaluation1TextGenerator from '../../Models/Evaluation1/TextGenerator'
import Evaluation1Flexible from '../../Models/Evaluation1/Flexible'
import {Edit, Watch} from 'react-feather'
import mainservice from '../../services/MainService'
import './CoachA.scss'
import BookSelector from '../Admin/BookSelector'
import CoachSendSessionToHistory from './CoachSendSessionToHistory'

enum SortMode {
  standard,
  quality,
  tableView,
  groupView,
}
type Props = {
}

type State = {
  loading: boolean,
  session?: SessionDTO,
  bookId: number,
  editField: string,
  tabNumber: number,
  state: [string, number][],
  sortMode: SortMode
}

class CoachA extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    const bookId: number = (mainservice.nav.getVal('book') || -1) as number
    this.state = {
      loading: false,
      session: new SessionDTO({hash: '', bookId: bookId}),
      editField: '',
      tabNumber: 0,
      state: [],
      sortMode: SortMode.standard,
      bookId: -1
    }
    this.getContent()
  }

  private stateMod(name: string, index: number) {
    let states = this.state.state
    let item = states.find(s => s[0] === name)
    if (item) {
      item[1] = index
    } else {
      states.push([name, index])
    }
    this.setState({state: states})
  }

  private stateGet(name: string): number {
    const item = this.state.state.find(s => s[0] === name)
    if (item) {
      return item[1]
    }
    return 0
  }

  private stateGetActiveClass(name: string, index: number) {
    const currentIndex = this.stateGet(name)
    if (currentIndex === index) {
      return 'active w3-white'
    }
    return 'w3-blue'
  }

  private stateGetDisabledStyle(name: string, index: number) {
    const currentIndex = this.stateGet(name)
    if (currentIndex === index) {
      return {}
    }
    return {display: 'none'}
  }

  async getContent(_fresh?: boolean) {
    // We only need the sessionID to identify the book!
    const bookId: number = (mainservice.nav.getVal('book') || -1) as number
    this.setState({loading: true, bookId: bookId})
    const sessionId: string = mainservice.nav.getVal('session') as string
    if (!this.state || !this.state.session) { return }
    await this.state.session.set(sessionId, bookId)
    this.setState({loading: false})
  }

  renderRow(cD: CoachRowData, index: number) {
    let timeTaken = '-'
    const rowName = `answerrow-${index}`
    if (cD.date &&
      this.state.session &&
      this.state.session.coachData[index + 1] &&
      this.state.session.coachData[index + 1].date &&
      this.state.session.coachData[index + 1].date.getTime() - cD.date.getTime() > 0
    ) {
      timeTaken = mainservice.time.convertTimeToHourMinuteSeconds(
        new Date(cD.timeTaken || 0)
      )
    }
    const answers = cD.answers.map((a, i) => {
      const answerName = `${rowName}-answer-${i}`
      const answerResultName = `${answerName}-result`
      const answerConsistencyName = `${answerName}-consistency`
      const color = EffectiveRatingColor(cD.surveypart, a.letter)
      return <div className="answer-container">
        <div
          className={`answer-letter w3-rightbar w3-border-${color}`}
          title={EffectiveRating(cD.surveypart, a.letter)}
        >
          <div className={`item ${a.selected ? ' selected ' : ' '} w3-border-${color}`}>
            {a.letter}
          </div>
        </div>

        <div className="answer-content w3-border-top">
          <div className="answer-first-row w3-row">
            <div className="answer w3-col s10">{a.content}</div>
            <div className="buttons w3-col s2 w3-blue">
              <button
                className={`secondary-button w3-margin w3-border w3-border-white w3-round ${this.stateGetActiveClass(answerName, 0)}`}
                onClick={() => this.stateMod(answerName, 0)}
              >Ergebnis</button>
              <button
                className={`secondary-button w3-margin w3-border w3-border-white w3-round ${this.stateGetActiveClass(answerName, 1)}`}
                onClick={() => this.stateMod(answerName, 1)}
              >Resultat</button>
            </div>
          </div>
          <div className="answer-second-row w3-border-blue">
            <div className="answer-result w3-cell w3-animate-opacity" style={this.stateGetDisabledStyle(answerName, 0)}>
            {
              this.state.editField != answerResultName &&
              <>
                <Edit onClick={() => this.setState({editField: answerResultName})}/>
                <div
                  dangerouslySetInnerHTML={{__html: a.result}}
                ></div>
              </>
            }
            {
              this.state.editField === answerResultName &&
              <InputA returnVal={(newResult: string, self: any) => {
                self.state.session.addElementProp(cD.pageElementId, 'result', a.letter, newResult, 'misc').then(() => {
                  this.setState({editField: ``})
                  // self.getContent()
                })
              }} parent={this} value={a.result} type='textArea'/>
            }
            </div>
            <div className="answer-consistency w3-cell w3-animate-opacity" style={this.stateGetDisabledStyle(answerName, 1)}>
            {
              this.state.editField != answerConsistencyName &&
              <>
                <Edit onClick={() => this.setState({editField: answerConsistencyName})}/>
                <div
                  dangerouslySetInnerHTML={{__html: a.consistency}}
                ></div>
              </>
            }
            {
              this.state.editField === answerConsistencyName &&
              <InputA returnVal={(newResult: string, self: any) => {
                self.state.session.addElementProp(cD.pageElementId, 'consistency', a.letter, newResult, 'misc').then(() => {
                  this.setState({editField: ``})
                  // self.getContent()
                })
              }} parent={this} value={a.consistency} type='textArea'/>
            }
            </div>
          </div>
        </div>

      </div>

    })
    return <>
      <div key={index} className="w3-card w3-margin">
        <header className="w3-container w3-blue">
          <h3>{cD.surveypart}</h3>
          <div className="answer-time">
            <span className="time-symbol"><Watch /></span> {timeTaken}
          </div>
        </header>
        <div className="w3-bar w3-blue">
          <button
            className={`secondary-button w3-border w3-border-white w3-round w3-margin ${this.stateGetActiveClass(rowName, 0)}`}
            onClick={() => this.stateMod(rowName, 0)}
          >Frage</button>
          <button
            className={`secondary-button w3-border w3-border-white w3-round w3-margin ${this.stateGetActiveClass(rowName, 1)}`}
            onClick={() => this.stateMod(rowName, 1)}
          >Analyse</button>
        </div>
        <div
          className="question w3-padding w3-animate-opacity"
          style={this.stateGetDisabledStyle(rowName, 0)}
          dangerouslySetInnerHTML={{__html: cD.question}}
        ></div>
        <div className="analyse w3-padding w3-animate-opacity" style={this.stateGetDisabledStyle(rowName, 1)}>
          {
            this.state.editField != `${index}-analyse` &&
            <>
              <Edit onClick={() => this.setState({editField: `${index}-analyse`})}/>
              <div>
                {cD.analyse}
              </div>
            </>
          }
          {
            this.state.editField === `${index}-analyse` &&
            <InputA returnVal={(newAnalyse: string, self: any) => {
              self.state.session.addElementProp(cD.pageElementId, 'analyse', 'misc', newAnalyse, 'misc').then(() => {
                this.setState({editField: ``})
                // self.getContent()
              })
            }} parent={this} value={cD.analyse} type='textArea'/>
          }
        </div>
        <div className="answers">
          {
            answers
          }
        </div>
      </div>
    </>
  }

  async changeSession(sessionHash: string) {
    mainservice.nav.setVal('session', sessionHash)
    this.setState({state: []})
    await this.getContent()
  }
  async changeBookId(bookId: string) {
    mainservice.nav.setVal('book', bookId)
    this.setState({state: []})
    await this.getContent()
  }

  renderTexts() {
    let flexible = new Evaluation1Flexible((this.state.session) ? this.state.session.sessionData: [])
    const pBC = flexible.pointsByCategory
    const texts = flexible.getAllTextAndValidIndex()
    return <>
      <table>
        <caption>Erreichte Punkte</caption>
        <thead><tr><th>s1</th><th>s2</th><th>s3</th><th>s4</th></tr></thead>
        <tbody><tr><td>{pBC[0]}</td><td>{pBC[1]}</td><td>{pBC[2]}</td><td>{pBC[3]}</td></tr></tbody>
      </table>
      <h3>Auswahl des Antworttextes</h3>
      <div>
        {
          texts.texts.map((text, index) => {
            return <div
                key={index}
                className={(texts.index === index) ? 'w3-yellow w3-border w3-padding w3-margin' : 'w3-border w3-padding w3-margin'}
              >
              Bedingungen:
              <code>
                {
                  text.logic
                }
              </code>
              <h3>Text</h3>
              <div>{text.text}</div>
            </div>
          })
        }
      </div>
    </>
  }

  renderSortSelector() {
    const activeClass = 'secondary-button w3-ripple w3-blue'
    const passiveClass = 'secondary-button w3-white w3-border w3-border-blue'
    const sortOptions = [
      {
        displayName: 'Standard',
        sortMode: SortMode.standard
      },
      {
        displayName: 'Antwortsqualität',
        sortMode: SortMode.quality
      },
      {
        displayName: 'Tabelle',
        sortMode: SortMode.tableView
      },
      {
        displayName: 'Gruppen-Tabelle',
        sortMode: SortMode.groupView
      },
    ]
    return <>
      Sortierung:
      {
        sortOptions.map(
          (so) => <button
            key={so.sortMode}
            className={`${(this.state.sortMode === so.sortMode) ? activeClass : passiveClass}`}
            onClick={() => {
              this.setState({
                sortMode: so.sortMode
              })
            }}
          >
            {so.displayName}
          </button>
        )
      }
    </>
  }

  render() {
    const sessionHash = mainservice.nav.getVal('session') as string
    const emails = (sessionHash) ? sessionHash.split(/[,; ]+/) : ['']
    return <div className='coachPage admin-tool'>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <button className='secondary-button' onClick={() => this.setState({tabNumber: 0})}>Fragen</button>
      <button className='secondary-button' onClick={() => this.setState({tabNumber: 1})}>Antworten</button>
      <div className='w3-container w3-center'>
        Session ID oder E-Mail Adresse eingeben: <InputA
          returnVal={(sessionHash: string) => {
            sessionHash = sessionHash.split(/[,; \n]+/).join(';')
            /*
            if (sessionHash.search('@') > -1) {
            }
            */
            this.changeSession(sessionHash)
          }}
          parent={this}
          value={mainservice.nav.getVal('session') as string}
          />
          <CoachSendSessionToHistory sessionHash={this.state.session?.sessionIds || this.state.session?.hash || ''} />
        <BookSelector
          selectBook={(id: number) => {
            this.changeBookId(id + '')
          }}
          currentBookId={parseInt(mainservice.nav.getVal('book') + '' || '-1', 10)}
        />
        {this.renderSortSelector()}
      </div>

      { this.state.sortMode != SortMode.groupView &&
        emails.map(email => this.renderContent(email))
      }
      { this.state.sortMode === SortMode.groupView && this.state.session &&
        RenderAsGroupTable(this.state.session, emails)
      }
    </div>
  }

  renderContent(email: string) {
    let coachData: CoachRowData[] = []
    const sessionData = (this.state.session) ? this.state.session.sessionData.filter(s => (!email || s.email === email)) : []
    if (this.state.session && this.state.session.coachData) {
      // get qualities of answers:
      coachData = this.state.session.getCoachData(email)

      if (this.state.sortMode === SortMode.quality) {
        coachData = coachData.sort((a, b) => (a.effectiveQualityNumber || 0) - (b.effectiveQualityNumber || 0))
      } else {
        coachData = coachData.sort((a, b) => (a.origSortNumber < b.origSortNumber) ? -1 : 1)
      }
    }
    const sessionHash = mainservice.nav.getVal('session')
    if (!this.state.loading && this.state.session) {
      console.log('this.state.session.sessionData', this.state.session.sessionData)
    }
    return <>
      <h2>{email}</h2>
      {
        this.state.loading && <Spinner key={'bookSpinner'} />
      }
      {
        !this.state.loading && this.state.session && this.state.session.sessionData.length === 0 &&
        <div>
          <div className="warning-box">
            {
              sessionHash ? 'Hash oder Mail ungültig - oder Nutzer hat keine Daten eingetragen' : 'Bitte Session ID oder Mail eingeben'
            }
            
          </div>
        </div>
      }
      {
        (
          this.state.sortMode === SortMode.tableView &&
          this.state.session && this.state.session.sessionData.length > 0
        ) &&
        RenderAsTable(coachData, sessionData, email)
      }
      {
        !this.state.loading && this.state.tabNumber === 0 && this.state.session && this.state.session.getCoachData().length > 0 &&
        this.state.session &&
        this.state.session.sessionData.length > 0 &&
        (this.state.sortMode === SortMode.standard || this.state.sortMode === SortMode.quality) &&
        coachData.map((cD, index) => this.renderRow(cD, index))
      }
      {
        !this.state.loading && this.state.tabNumber === 1 &&
        this.state.session &&
        this.state.session.sessionData.length > 0 &&
        this.renderTexts()
      }
    </>
  }
}

type NameCount = {name: string, count: number}
class WorstQuestions {
  worstQuestions: NameCount[] = []
  constructor() {
    this.worstQuestions = []
  }
  public add(name: string) {
    const q: NameCount | undefined = this.worstQuestions.find(w => w.name === name)
    if (q) {
      q.count += 1
    } else {
      this.worstQuestions.push({
        name: name,
        count: 1
      })
    }
  }
  public get() {
    return this.worstQuestions.sort((a,b) => b.count - a.count)
  }
  public check(d: {surveypart: string, effectiveQualityNumber: number}) {
    if (d.effectiveQualityNumber === 0) {
      this.add(d.surveypart)
    }
  }
}

const RenderAsGroupTable = (session: SessionDTO, emails: string[]) => {
  const columns = [
    {shortname: '', name: ''},
    {shortname: 'A', name: 'Führungsstil Anweisend'},
    {shortname: 'T', name: 'Führungsstil Trainierend'},
    {shortname: 'C', name: 'Führungsstil Coachend'},
    {shortname: 'D', name: 'Führungsstil Delegeierend'},
    {shortname: '1', name: 'Effektivität 1 (Unangemessen)'},
    {shortname: '2', name: 'Effektivität 2 (Mäßig)'},
    {shortname: '3', name: 'Effektivität 3 (Gut)'},
    {shortname: '4', name: 'Effektivität 4 (Ausgezeichnet)'},
    {shortname: 'F', name: 'Führungsstil'},
    {shortname: 'E', name: 'Effektivität'},
  ]
  const worstQuestions = new WorstQuestions()
  let users = emails.map(email => {
    const userCoachData = session.getCoachData(email)
    // Get worst Questions:
    userCoachData.forEach(d => worstQuestions.check(d))
    // Get Totals
    const vs = getUserTotals(userCoachData, session.sessionData.filter(s => s.email === email))
    let columns = [email].concat(vs.flexibleCountsStr, vs.effectiveCountsStr, vs.flexibleSumStr, vs.effectiveSumStr)
    return {
      name: email,
      columns: columns
    }
  })
  // Find avaerage:
  const sums = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  const userCount = users.length
  users.forEach(u => {
    for (let i = 0; i < 10; i++) {
      sums[i] += parseInt(u.columns[1 + i] || '0', 10) || 0
    }
  })
  for (let i = 0; i < 10; i++) {
    sums[i] /= userCount
  }
  users.push({name: 'Durschnitt', columns: ['Durchschnitt'].concat(sums.map(s => Math.round(s * 10) / 10 + ''))})
  const flops = worstQuestions.get().map(w => {
    return {
      columns: [`Flop ${w.name}`, '', '', '', '', w.count + '', '', '', '', '', ''],
      name: `Flop ${w.name}`
    }
  })

  users = users.concat(flops)
  let csv: string = `"${columns.map(c => c.shortname).join('";"')}"\n` +
    users.map(u => `"${u.columns.join('";"')}"`).join('\n')
  return <>
    <table className='coachgrouptable'>
      <thead><tr>
        { columns.map(c => <th
          key={`groupHeader-${c.shortname}`}
          title={c.name}
          >
          {c.shortname}
        </th>)}
      </tr></thead>
      <tbody>
      {
        users.map(u => <tr key={`${u.name}`}>
            { u.columns.map((c, index) => <td key={`${u.name}-${index}`}>
                {c}
              </td>) }
          </tr>)
      }
      </tbody>
    </table>
    <a
      download='group.csv'
      target='_blank'
      href={`data:text/csv;charset=utf-8,${encodeURI(csv)}`}
    >Download</a>
  </>
}

const RenderAsTable = (coachData: CoachRowData[], sessionData: SessionItem[], email: string) => {
  // Generate Table - so we can use it for export, as well:
  type RowType = [string, string, string, string, string, string?]
  let renderData: RowType[] = []
  // get Flexibible Sum:
  const vs = getUserTotals(coachData, sessionData)

  renderData.push(['Stil-Flexibilität', `${vs.flexibleSum}`, '', '', '', 'rowClass'])
  renderData.push(['Stil-Effektivität', `${vs.effectiveSum}`, '', '', '', 'rowClass'])
  renderData.push(['Gewählter Führungsstil', 'Anweisen', 'Trainieren', 'Coachen', 'Delegieren', 'rowClassHeader'])
  renderData.push(['In Summe', `${vs.flexibleCounts[0]}`, `${vs.flexibleCounts[1]}`, `${vs.flexibleCounts[2]}`, `${vs.flexibleCounts[3]}`, 'rowClassBold'])
  renderData.push(['Effektivität/Erfolgswahrscheinlichkeit', '1 gering', '2 niedrig', '3 hoch', '4 sehr hoch', 'rowClassHeader'])
  renderData.push(['In Summe', `${vs.effectiveCounts[0]}`, `${vs.effectiveCounts[1]}`, `${vs.effectiveCounts[2]}`, `${vs.effectiveCounts[3]}`, 'rowClassBold'])
  renderData.push(['Zuordnung einzelne Situationen', '1 gering', '2 niedrig', '3 hoch', '4 sehr hoch', 'rowClassHeader'])
  for (let i = 0, m = coachData.length; i < m; i++) {
    // let row = [`${i+1} ${coachData[i].surveypart}`]
    let row = [`${i+1}`]
    for (let j = 0, n = 4; j < n; j++) {
      row.push((j === coachData[i].effectiveQualityNumber) ? 'X' : ' ')
    }
    // row.push(coachData[i].timeTaken + '' || '-')
    renderData.push(row as RowType)
  }
  let csv = `"${email}";"";"";"";""\n` + renderData.map(row => '"' + row.slice(0,5).join('";"') + '"').join('\n')
  return <>
    <table className="coachsingletable"><tbody>
      {
        renderData.map((rD, index: number) => <tr key={index} className={rD[5]}>
          {
            rD.slice(0,5).map((c, jndex: number) => <td key={jndex}>{c}</td>)
          }
        </tr>)
      }
    </tbody></table>
    <a
      download={`${email}.csv`}
      target='_blank'
      href={`data:text/csv;charset=utf-8,${encodeURI(csv)}`}
    >Download</a>
  </>
}

const getUserTotals = (coachData: CoachRowData[], sessionData: SessionItem[]) => {
  const flexible = new Evaluation1Flexible(sessionData)
  const flexibleSum = flexible.flexiblePoints
  // get Effecitve  Sum:
  const effective = new Evaluation1Effective(sessionData)
  const effectiveSum = effective.effective
  // Flexible Counts:
  const flexibleValues: number[] = coachData.map(c => c.flexibleQualityNumber)
  const flexibleCounts = [0,1,2,3].map(i => flexible.pointsByCategory[i])
  // Effective Counts:
  const effectiveCounts = [0,1,2,3].map(i => {
    const values: number[] = coachData.map(c => (c.effectiveQualityNumber === i) ? 1 : 0)
    return values.reduce((a, b: number) => a + b, 0)
  })
  return {
    flexibleSum: flexibleSum,
    flexibleSumStr: flexibleSum + '',
    effectiveSum: effectiveSum,
    effectiveSumStr: effectiveSum + '',
    flexibleCounts: flexibleCounts,
    flexibleCountsStr: flexibleCounts.map(c => c + ''),
    effectiveCounts: effectiveCounts,
    effectiveCountsStr: effectiveCounts.map(c => c + ''),
  }
}

export default CoachA
