// import React, { useState } from 'react'
import RenderImage from '../MediaManager/RenderImage'

type PageContent = {
  headline: string,
  content: string,
  linkTarget: string,
  linkContent: string,
  imageId: number,
  imagePosition: string,
  imageClass: string,
  contentClass: string,
  paragraphClass: string,
  kind?: string
}

const pageContent = [
  {
    headline: 'The beginning',
    content: 'Ist englisch und heißt "der Anfang" ',
    linkTarget: '',
    linkContent: '',
    imageId: 313,
    imagePosition: 'right',
    imageClass: 'l4 m12',
    contentClass: '',
    paragraphClass: 'l8 m12',
    kind: 'main-headline'
  },
  {
    headline: 'Der Anfang',
    content: 'Sollte immer einfach sein',
    linkTarget: 'https://en.wikipedia.org/wiki/Darth_Vader',
    linkContent: 'Wiki Link',
    imageId: 153,
    imagePosition: 'left',
    imageClass: 'l6 m12',
    contentClass: '',
    paragraphClass: 'l6 m12'
  },
  {
    headline: 'Hier gibt es kein Bild',
    content: 'Originally a slave on Tatooine, Anakin Skywalker is a Jedi prophesied to bring balance to the Force. He is lured to the dark side of the Force by Chancellor Palpatine and becomes a Sith Lord, assuming the title of Darth Vader. After a lightsaber battle with his former mentor Obi-Wan Kenobi on Mustafar, in which he is severely injured, Vader is transformed into a cyborg. He then serves the Galactic Empire for over two decades as its chief enforcer. Vader ultimately redeems himself by saving his son, Luke Skywalker, and killing Palpatine, sacrificing his own life in the process.[6] He is also the secret husband of Padmé Amidala, the biological father of Princess Leia, and the grandfather of Kylo Ren (Ben Solo). ',
    linkTarget: '',
    linkContent: '',
    imageId: -1,
    imagePosition: 'right',
    imageClass: '',
    contentClass: 'l9 m12',
    paragraphClass: ''
  },
  {
    headline: 'Die Reise',
    content: 'Stay with the flow',
    linkTarget: '',
    linkContent: '',
    imageId: 144,
    imagePosition: 'right',
    imageClass: 'l6 m12',
    contentClass: '',
    paragraphClass: 'l6 m12'
  },
  {
    headline: 'Hier gibt es kein Bild',
    content: 'Originally a slave on Tatooine, Anakin Skywalker is a Jedi prophesied to bring balance to the Force. He is lured to the dark side of the Force by Chancellor Palpatine and becomes a Sith Lord, assuming the title of Darth Vader. After a lightsaber battle with his former mentor Obi-Wan Kenobi on Mustafar, in which he is severely injured, Vader is transformed into a cyborg. He then serves the Galactic Empire for over two decades as its chief enforcer. Vader ultimately redeems himself by saving his son, Luke Skywalker, and killing Palpatine, sacrificing his own life in the process.[6] He is also the secret husband of Padmé Amidala, the biological father of Princess Leia, and the grandfather of Kylo Ren (Ben Solo). ',
    linkTarget: '',
    linkContent: '',
    imageId: -1,
    imagePosition: 'left',
    imageClass: '',
    contentClass: 'l9 m12',
    paragraphClass: ''
  },
  {
    headline: 'Nie aufhören',
    content: 'Man sollte nie aufhören zu lernen, denn es macht uns glücklich!',
    linkTarget: '',
    linkContent: '',
    imageId: 309,
    imagePosition: 'left',
    imageClass: 'l6 m12',
    contentClass: '',
    paragraphClass: 'l6 m12'
  }
]

const StartPage = (props: {}) => {
  return <div className='landingpage h100 scrollY'>
    {
      pageContent.map((pC, index) => RenderParagraph(pC, index)
      )
    }
  </div>
}

const RenderParagraph = (props: PageContent, index: number) => {
  const kindClass = props.kind || ''
  return <div key={`${index}`} className={`paragraph ${props.imagePosition} ${(props.imageId === -1) ? 'text-only' : 'text-and-image'} ${kindClass}`}>
    <div className={`paragraph-content w3-row`}>
      {
        props.imageId > -1 &&
        <RenderImage
          className={`w3-col paragraph-image-content ${props.imageClass}`}
          fileId={props.imageId}
        />
      }
      <div className={`w3-col paragraph-text-content ${props.paragraphClass}`}>
        {
          props.headline &&
          <h2
            className={`paragraph-headline-content`}
            dangerouslySetInnerHTML={{__html: props.headline}}
          ></h2>
        }
        <div
          className={`paragraph-text-content-content`}
          dangerouslySetInnerHTML={{__html: props.content}}
        ></div>
        {
          props.linkTarget &&
          <a href={props.linkTarget}>
            <button
              className='paragraphLinkButton primary-button'
              dangerouslySetInnerHTML={{__html: props.linkContent}}
            ></button>
          </a>
        }
      </div>
    </div>
  </div>
}
export default StartPage
