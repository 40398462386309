import React from 'react'
import mainservice from '../../services/MainService'
// import InputA from '../InputA/InputA'
// import config from '../../config.json'
import {Setter} from '../../services/ComService'

enum Status {
  standard,
  newUsersOkay
}

type Props = {
}

type State = {
  tags: string[],
  emails: string[],
  tagsRaw: string,
  emailsRaw: string,
  status: Status,
  errMsg: string
}

export default class BulkGenerateUsers extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      tags: [],
      emails: [],
      tagsRaw: '',
      emailsRaw: '',
      status: Status.standard,
      errMsg: ''
    }
  }

  text2Array(text: string, test: any): string[] {
    return text.replace(/<[^>]*>/g, '').split(/[,; "]+/).filter(
      (x) => x != '' &&
        x.search(test) > -1
    )
  }

  setEmails(data: string, self: any): string[] {
    const emails = this.text2Array(data, /.+\@.+\..+/)
    self.setState({emails: emails})
    return emails
  }

  setTags(data: string, self: any): string[] {
    const tags = this.text2Array(data, /[a-z0-9A-Z]{1}/)
    self.setState({tags: tags})
    return tags
  }

  reset() {
    this.setState({
      emails: [],
      tags: [],
      status: Status.standard,
      errMsg: '',
      tagsRaw: '',
      emailsRaw: ''
    })
  }

  async create() {
    const emails = this.setEmails(this.state.emailsRaw, this)
    const tags = this.setTags(this.state.tagsRaw, this)
    const result = await Setter('users/add', {
      tags: tags,
      emails: emails
    }, {retryName: `${emails.join(',')}`})
    if (result && (result as {status: string}).status === 'okay') {
      this.setState({
        status: Status.newUsersOkay
      })
    } else {
      this.setState({
        errMsg: 'Vorgang hat nicht funktioniert - noch einmal probieren!'
      })
    }
  }

  renderNewUsersForm() {
    return <div className='bulkGenerateUsers'>
      <div className='description'>
        Beliebig viele eMailAdressen von neuen Usern können hier eingetragen werden - Erlaubte Trennzeichen: Komma, Leerzeichen oder Semikolon.
      </div>
      <input
        value={this.state.emailsRaw}
        onChange={(event) => {
          this.setState({
            emailsRaw: event.target.value
          })
        }}
      />
      <div className='description'>
        Wir sollten auf jeden Fall Tags vergeben, um die Nutzer hinterher zuordnen zu können. Gute Tags könnten z.B. Firmennamen sein.
      </div>
      <input
        value={this.state.tagsRaw}
        onChange={(event) => {
          this.setState({
            tagsRaw: event.target.value
          })
        }}
      />
      {
        this.state.errMsg &&
        <div className='errMsg'>
          {this.state.errMsg}
        </div>
      }
      <div className='description'>
        Bereit zum absenden?
      </div>
      <button
        className='secondary-button'
        onClick={() => {this.create()}}
      >
        Anlegen
      </button>
      <button
        className='secondary-button'
        onClick={() => {
          this.reset()
        }}
      >
        Abbrechen
      </button>
    </div>
  }



  render() {
    return <div className='admin-tool'>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'userList']])}>Abbrechen</button>
      {
        this.state.status === Status.newUsersOkay &&
        <div className='description'>
          Neue Benutzer erfolgreich angelegt:
          <ul>
            {this.state.emails.map((email) => <li key={email}>{email}</li>)}
          </ul>
          <button
            className='secondary-button'
            onClick={() => this.reset()}
          >
            Weitere Nutzer anlegen
          </button>
        </div>
      }
      {
        this.state.status === Status.standard &&
        this.renderNewUsersForm()
      }
    </div>
  }
}
