import { Getter } from "../../services/ComService"

export default function CoachSendSessionToHistory(props: {sessionHash: string}) {
  if (props.sessionHash.search(';') > -1) { return null }
  console.log('props.sessionHash1', props.sessionHash)
  const sessionHash = props.sessionHash.split(',')[0]
  console.log('props.sessionHash2', sessionHash)
  return <div>
    <button
      onClick={async () => {
        if (sessionHash && window.confirm(`${sessionHash} wirklich archivieren?`)) {
          await Getter(`session/${sessionHash}/archive`)
          window.location.reload()
        }
      }}
    >Session Archivieren?</button>
    {sessionHash}
  </div>
}