import FileDTO, { IFile } from './FileDTO'
import {Getter, Setter } from '../services/ComService'
import { Emoji2PlainText, Emoji2Unicode } from '../services/EmojiServices'
// import Preferences from '../components/Admin/Preferences'
export enum PreferenceType {
  boolean,
  text,
  date,
  file,
  number,
}

type Preference = {
  id?: number,
  name: string,
  key1: string,
  key2: string,
  values: PreferenceValue[],
  kind: string, // Which part of preferences is this in?
  type: PreferenceType, // WHich type of value?
  standard: string,
  description?: string,
}

export type PreferenceValue = {
  id?: number,
  value1: string,
  value2: string,
  start: Date | null,
  end: Date | null,
  file: FileDTO | null,
}

let prefs: Preference[] = [
  {
    name: 'Shop aktiviert',
    key1: 'shop',
    key2: 'enable',
    values: [],
    kind: 'default',
    type: PreferenceType.boolean,
    standard: '0'
  },
  {
    name: 'Nutzer sammeln beim Erhalt eines Buches sofort alle Karten',
    key1: 'collectable',
    key2: 'instantCollectOnBookReceive',
    values: [],
    kind: 'default',
    type: PreferenceType.boolean,
    standard: '0'
  },
  {
    name: 'Bezahl Überweisungs Anweisungstext ',
    key1: 'shop',
    key2: 'PaymentManualTransferExplainText',
    values: [],
    kind: 'default',
    type: PreferenceType.text,
    standard: '0',
    description: `<p>Platzhalter einbauen: <ul>
      <li><pre class='w3-show-inline-block'>{TOTALWITHTAX}</pre> - Höhe des zu zahlenden Betrages</li>
      <li><pre class='w3-show-inline-block'>{TAX}</pre> - Höhe der MWS</li>
      <li><pre class='w3-show-inline-block'>{CARTHASH}</pre> - Referenz Nummer zu diesem Einkauf</li>
      <li><pre class='w3-show-inline-block'>{CARTSUMMARY}</pre> - Zusammenfassun der Positionen</li>
      <li><pre class='w3-show-inline-block'>{FIRSTNAME}</pre> - Vorname (mit Vorrangehendem Leerzeichen)</li>
    </ul></p>
    `
  },
  {
    name: 'Bestätigungsmail, wenn Einkäufe dem User zugeordnet wurden:',
    key1: 'shop',
    key2: 'cartFullfilled',
    values: [],
    kind: 'default',
    type: PreferenceType.text,
    standard: '0',
    description: `<p>Platzhalter einbauen: <ul>
      <li><pre class='w3-show-inline-block'>{TOTALWITHTAX}</pre> - Höhe des zu zahlenden Betrages</li>
      <li><pre class='w3-show-inline-block'>{TAX}</pre> - Höhe der MWS</li>
      <li><pre class='w3-show-inline-block'>{CARTHASH}</pre> - Referenz Nummer zu diesem Einkauf</li>
      <li><pre class='w3-show-inline-block'>{CARTSUMMARY}</pre> - Zusammenfassun der Positionen</li>
      <li><pre class='w3-show-inline-block'>{FIRSTNAME}</pre> - Vorname (mit Vorrangehendem Leerzeichen)</li>
    </ul></p>
    `
  },
]

export class PreferenceDTO {
  id: number = -1
  name: string
  key1: string
  key2: string
  values: PreferenceValue[]
  kind: string
  type: PreferenceType
  standard: any
  description: string
  constructor(data: Preference) {
    this.id = data.id || -1
    this.name = data.name
    this.key1 = data.key1
    this.key2 = data.key2
    this.values = []
    this.kind = data.kind
    this.type = data.type
    this.standard = data.standard
    this.description = data.description || ''
  }

  public initData(data: IPreference) {
    // this.key1 = data.key1
    // this.key2 = data.key2
    try {
      this.id = data.id || -1
    } catch(err) {
      throw('not logged in?')
    }
    this.values = data.values.map(v => {
      let newV = {
        id: v.id,
        value1: Emoji2Unicode(v.value1),
        value2: Emoji2Unicode(v.value2),
        start: v.start ? new Date(v.start.date) : null,
        end: v.end ? new Date(v.end.date) : null,
        file: null,
      }
      return newV
    })
    this.kind = data.kind
  }
  
  /*
  public async getFromServer() {
    
  }
  */

  public async saveValue(valueId: number) {
    const value = this.values.find(v => v.id === valueId)
    if (!value) {
      return
    }
    const data: any = await Setter('preferences/set', {
      id: value.id,
      fileId: value.file?.id || -1,
      key1: this.key1,
      key2: this.key2,
      value1: Emoji2PlainText(value.value1),
      value2: Emoji2PlainText(value.value1),
      start: value.start,
      end: value.end,
      kind: this.kind,
      type: this.type,
    })
    if (valueId === -1) {
      const newData: any = await Setter('preferences/getPreference', {
        id: this.id
      })
      this.initData(newData)
    } else {
      this.initData(data)
    }
    
  }

  public async saveDate(valueId: number, d: Date | null, t: 'start' | 'end') {
    const value = this.values.find(v => v.id === valueId)
    if (!value) {
      return
    }
    if (t === 'start') {
      value.start = d
      if (value.start && value.end && value.start > value.end) {
        value.end = value.start
      }
    } else {
      value.end = d
      if (value.start && value.end && value.start > value.end) {
        value.start = value.end
      }
    }
    await this.saveValue(valueId)
  }

  public async removeValue(valueId: number) {
    this.values = this.values.filter(v => v.id !== valueId)
    const data: any = await Setter('preferences/removeValue', {
      preferenceId: this.id,
      valueId: valueId
    })
    this.initData(data)
  }

  public async setValue(valueId: number, valueValue: string) {
    let value = this.values.find(v => v.id === valueId) || {
      id: valueId,
      value1: '',
      value2: '',
      start: null,
      end: null,
      file: null,
    }
    const result: any = await Setter('preferences/set', {
      id: (value.start === null && value.end === null) ? -1 : value.id,
      fileId: value.file?.id || -1,
      key1: this.key1,
      key2: this.key2,
      value1: Emoji2PlainText(valueValue),
      value2: Emoji2PlainText(value.value2),
      start: value.start,
      end: value.end,
      kind: this.kind,
      type: this.type,
    })
    this.initData(result)
    return valueValue
  }

  public getValues(): PreferenceValue[] {
    return this.values.filter(v => !(v.start === null && v.end === null))
  }

  public getDefaultValue(): PreferenceValue {
    return this.values.find(v => v.start === null && v.end === null) || {
      id: -1,
      value1: this.standard,
      value2: '',
      start: null,
      end: null,
      file: null,
    }
  }

  public initNewValue() {
    this.values.push(
      {
        id: -1,
        value1: '',
        value2: '',
        start: new Date(),
        end: new Date(),
        file: null,
      }
    )
  }
}

export class PreferencesDTO {

  prefs: PreferenceDTO[] = []
  initDone: boolean = false

  constructor() {
    this.prefs = prefs.map(p => new PreferenceDTO(p))
    // this.getFromServer()
  }

  public async init() {
    if (!this.initDone) {
      await this.getFromServer()
    }
  }

  private initData(data: IPreference[]) {
    try {
      data.forEach(d => {
        const l = this.getPref(d.key1, d.key2)
        if (!l) { return }
        l.initData(d)
      })
    } catch(err) {
      throw('not logged in?')
    }
    this.initDone = true
  }

  public getPref(key1: string, key2: string) {
    return this.prefs.find(l => l.key1 === key1 && l.key2 === key2)
  }

  private async getFromServer() {
    const data = await Getter('preferences/list')
    this.initData(data)
  }

  public list() {
    return this.prefs
  }
}

export interface IPreferenceValue {
  id: number,
  value1: string,
  value2: string,
  start: {date: string} | null,
  end: {date: string} | null,
  file: IFile,
}

export interface IPreference {
  id: number,
  key1: string,
  key2: string,
  values: IPreferenceValue[],
  kind: string,
}

const PreferenceService = new PreferencesDTO()
export default PreferenceService
