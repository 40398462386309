import mainservice from "../../services/MainService"

export default function BackButton(props: {
  className?: string,
  tag?: 'button' | 'a'
}) {
  const backName = mainservice.historyBackName()
  if (!backName) { return null }
  if (props.tag === 'a') {
    return <a
      href='/'
      className={props.tag || ''}
      onClick={(e) => {
        e.preventDefault()
        mainservice.historyBack()
      }}
    >{backName}</a>
  }
  return <button
      className={props.tag || 'w3-button'}
      onClick={() => {
        mainservice.historyBack()
      }}
    >{backName}</button>
}
