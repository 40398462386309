import { useState, useEffect } from "react"
import { Setter, Getter } from '../../services/ComService'
import CartDTO, { cart, CartStatus, ICart, TranslateCartStatus } from '../../DTO/ShopDTO'
import Spinner from '../Spinner/Spinner'
import mainservice from "../../services/MainService"
import UserDashMainMenu from "../User/UserDashMainMenu"
// import {SanitizeDate} from '../services/DateTime'

enum Status {
  loading,
  ready,
  more,
  history,
}

let carts: CartDTO[] = []
let historicCarts: CartDTO[] = []

export default function UserCarts() {
  const [status, setStatus] = useState(Status.loading)
  const [renderCounter, setRenderCounter] = useState(0)

  useEffect(() => {
    Getter('shop/getUserCarts').then(result => {
      console.log('result', result)
      const dtos = result.map((r: ICart) => new CartDTO(r, true))
      carts = dtos.filter(((c: CartDTO) => !c.HasSomeStatus([CartStatus.FULLFILLED, CartStatus.CANCELED])))
      historicCarts = dtos.filter(((c: CartDTO) => c.HasSomeStatus([CartStatus.FULLFILLED, CartStatus.CANCELED])))
      setStatus(Status.ready)
    })
  }, [])

  return <div className='w3-container h100 scrollY'>
    <UserDashMainMenu />
    {
      status === Status.loading &&
      <Spinner />
    }
    <div className='w3-container w3-auto w3-center'>
      <button
        className='secondary-button'
        onClick={() => {
          mainservice.navTo([['view', 'shop']])
        }}
      >Zurück zum Store</button>
    {
        historicCarts.length > 0 &&
        <button
          className='secondary-button'
          onClick={() => setStatus(status === Status.ready ? Status.history : Status.ready)}
        >
          {
            status === Status.history &&
            'Verstecke Verlauf'
          }
          {
            status === Status.ready &&
            'Zeige Verlauf'
          }
        </button>
    }
    </div>
    {
      status === Status.history &&
      historicCarts.map((c, index) => <RenderCart
          key={`${index}-${c.hash}`}
          cart={c}
          rerender={() => setRenderCounter(renderCounter + 1)}
        />)
    }
    {
      status === Status.ready && carts.length === 0 &&
      <div className='info-box'>Zur Zeit keine offenen Aufträge</div>
    }
    {
      status === Status.ready &&
      carts.map((c, index) => <RenderCart
          key={`${index}-${c.hash}`}
          cart={c}
          rerender={() => setRenderCounter(renderCounter + 1)}
        />)
    }
  </div>
}

function RenderCart(props: {cart: CartDTO, rerender: () => void}) {
  const cart = props.cart
  // const rerenderParent = props.rerender
  // const [status, setStatus] = useState(Status.ready)
  const leftCol = 'w3-col s3'
  const rightCol = 'w3-col s9'
  const buttonCol = 'w3-right'

  return <div className='w3-row w3-card w3-padding w3-margin'>
    <div className={leftCol}>Referenz Code</div>
    <div className={rightCol}>{cart.hash}</div>

    <div className={leftCol}>Datum</div>
    <div className={rightCol}>{cart.getModified()}</div>

    <div className={leftCol}>Status</div>
    <div className={rightCol}>{cart.GetCartStatus()} ({cart.status})</div>

    <div className={leftCol}>Bücher</div>
    <div className={rightCol}>
      {
        cart.listCart().map(b => <div
          key={b.id}
        >
          {b.name}
        </div>)
      }
    </div>

    <div className={leftCol}>Summe</div>
    <div className={rightCol}>{cart.totalUser()}</div>

    {
      !cart.HasSomeStatus([CartStatus.ISPAID, CartStatus.CANCELED, CartStatus.MARKEDASPAYED, CartStatus.FULLFILLED]) &&
      <>
        <div className={buttonCol}>
          <button
            className='w3-button w3-red'
            onClick={async () => {
              if (window.confirm('Willst Du wirklich die Bestellung Stornieren?')) {
                await cart.cancel()
                props.rerender()
              }
            }}
          >Stornieren</button>
        </div>

        <div className={buttonCol}>
          <button
            className='w3-button w3-yellow'
            onClick={async () => {
              if (window.confirm('Du hast bereits bezahlt?')) {
                await cart.markPaid()
                props.rerender()
              }
            }}
          >Als bezahlt markieren</button>
        </div>

        <div className={buttonCol}>
          <button
            className='w3-button w3-green'
            onClick={async () => {
              if (window.confirm('Zahlungsinformationen erneut senden?')) {
                await cart.sendPaymentInfo()
                props.rerender()
              }
            }}
          >Zahlungsinformationen erneut senden</button>
        </div>
      </>
    }
   
  </div>
}