import { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import UnitDTO, { UnitsDTO, IUnit, UnitType, UnitTypes } from '../../DTO/UnitDTO'
import BookDTO, { GetBooks } from '../../DTO/BookDTO'
import InputA from '../InputA/InputA'
import { Settings, XCircle, Square, CheckSquare, Minus } from 'react-feather'
import mainservice from '../../services/MainService'
import NumberArrayManager from 'numberarraymanager'
import DatePicker from 'react-datepicker'
import JoditReact from "jodit-react"
// import 'jodit/build/jodit.min.css'
import "react-datepicker/dist/react-datepicker.css"
import './UnitList.scss'
import AsyncWaitForAll from '../../services/AsyncWaitForAll'
import CheckManager from '../../services/CheckManager'

enum Status {
  init,
  loading,
  ready,
  userInput,
  editName,
  editBooks,
  inheritBooksFrom,
  editDescription,
}

const units = new UnitsDTO()
let books: BookDTO[] = []

const nas = new NumberArrayManager()

const RenderBookList = (props: {
    bookIds: number[],
    showAll?: boolean,
    showSelection?: number[],
    reRender: () => void,
    action: (addBookIdss: number[], removeBookIds: number[]) => void,
}) => {
  const [checkManager] = useState(new CheckManager())
  const [ReRender, setReRender] = useState(0)
  function simBookAdd (id: number) {
    checkManager.set(id)
    setReRender(ReRender + 1)
  }
  function simBookRemove (id: number) {
    checkManager.unset(id)
    setReRender(ReRender + 1)
  }
  const bookOut = (): BookDTO[] => {
    if (props.showSelection) {
      console.log('bookOut showSelection')
      return books.filter(b => (props.showSelection as number[]).some(id => b.id === id))
    }
    if (props.showAll) {
      console.log('bookOut showAll')
      return books
    }
    console.log('bookOut default')
    return books.filter(b => props.bookIds.some(id => b.id === id))
  }
  const RenderCheck = (rprops: {
    book: BookDTO,
  }) => {
    const inOut = checkManager.getVal(rprops.book.id)
    const checked = inOut === 0 && props.bookIds.some(b => b === rprops.book.id) || inOut === 1
    if (!props.showAll) { return <Minus className='cIcon' /> }
    if (checked) {
      return <CheckSquare
          className='cIcon pointer'
          onClick={() => {
            simBookRemove(rprops.book.id)
          }}
        />
    }
    return <Square
      className='cIcon pointer'
      onClick={() => {
        simBookAdd(rprops.book.id)
      }}
    />
  }
  const filteredBooks = bookOut()
  return <div className='bookList'>
    {
      filteredBooks.length === 0 &&
      <div className='warning-box'>Keine Bücher vorhanden</div>
    }
    <button
      className={`w3-button w3-ripple w3-green ${checkManager.changed() ? '' : 'w3-hide'}`}
      onClick={() => {
        props.action(checkManager.getSet(), checkManager.getUnset())
      }}
    >Änderungen übernehmen</button>
    {
      filteredBooks.map((b, i) => {
        let bookClass: string = ''
        if (checkManager.getVal(b.id) === 1) {
          bookClass = 'w3-green'
        }
        if (checkManager.getVal(b.id) === -1) {
          bookClass = 'w3-red'
        }
        return <div key={`bookselector-${b.id}-${i}`}>
          <RenderCheck
            book={b}
          />
          <a
            className={bookClass}
            href={b.getUrl()}
            target='_blank'
          >
            {b.name}
          </a>
        </div>
      })
    }
  </div>
}

const RenderUnitSelector = (props: {
  exclude: number[],
  selectUnit: (id: number) => void
}) => {
  const [typeKey, setTypeKey] = useState('event')

  return <>
    <div className='w3-dropdown-hover'>
      <button
        className={'w3-btn'}
      >
        {UnitTypes.find(u => u.key === typeKey)?.name}
      </button>
      <div
        className={`w3-dropdown-content w3-bar-block w3-border`}
      >
        {
          UnitTypes.map(u => <div
              key={`unitSelectItem-${u.key}`}
              className={`w3-bar-item w3-button ${u.key === typeKey ? 'w3-blue' : ''}`}
              onClick={() => {
                setTypeKey(u.key)
              }}
            >{u.name}</div>)
        }
      </div>
    </div>
    <div className='w3-dropdown-hover'>
      <button
        className={'w3-btn'}
      >Gruppe wählen</button>
      <div
        className={`w3-dropdown-content w3-bar-block w3-border`}
      >
        {
          units.list(typeKey as UnitType).filter(u => !props.exclude.some(e => u.id === e)).map(u => <div
              key={`unitSelectItem-${u.id}`}
              className='w3-bar-item w3-button'
              onClick={() => {
                props.selectUnit(u.id)
              }}
            >{u.name}</div>)
        }
      </div>
    </div>
  </>
}

const RenderUnit = (props: {
    unit: UnitDTO,
    reRender: () => void
  }) => {
  const unit = props.unit
  // const [dropDownOpen, setDropDownState] = useState(false)
  const [renderId, setRenderId] = useState(0)
  const [showDetailView, setDetailView] = useState(false)
  const [status, setStatus] = useState(Status.ready)
  const dropDownClass = 'unitRemove w3-bar-item w3-button'
  const reRender = () => { setRenderId(renderId + 1) }
  const RenderUsers = () => {

    return <div className='users'>
      {
        unit.users.isEmpty() && <div>Keine User</div>
      }
      {
        unit.users.list().map((u, i) => {
          return <span key={`unitlistuser-${u.id}`}>
          {
            i > 0 &&
            ', '
          }
          <a href={`mailto:${u.email}`}>
          {u.email}
          </a>
          <XCircle
            className='pointer cIcon'
            onClick={async () => {
              if (window.confirm(`User ${u.email} wirklich aus Gruppe ${unit.name} entfernen?`)) {
                await unit.removeUsers([u.id])
                props.reRender()
              }
            }}
          />
          </span>
        })
      }
      <hr />
    </div>
  }
  const RenderDescription = () => {
    let content = unit.description
    const [rc, setRc] = useState(0)
    switch(status) {
      case Status.editDescription:
        return <div>
            <JoditReact
              onChange={(c) => {content = c}}
              value={unit.description}
            />
            <button
              onClick={() => {
                unit.setDescription(content)
                setStatus(Status.ready)
              }}
            >Okay</button>
            <button
              onClick={() => {
                content = unit.description
                setStatus(Status.ready)
              }}
            >Abbrechen</button>
            <button
              onClick={() => {
                content = unit.description
                setRc(rc + 1)
              }}
            >Reset</button>
          </div>
    }
    if (!unit.description) { return null }
    return <div
        className='w3-border w3-padding'
        dangerouslySetInnerHTML={{__html: unit.description}}
      ></div>
  }
  const RenderDetailData = () => {
    // const [rddrs, setrddrs] = useState(0)
    return <div className='w3-col s12'>
      <RenderDescription />
      <RenderUsers />
      <RenderInheritBooks />
      <RenderBookList
        bookIds={unit.bookIds}
        showAll={status === Status.editBooks}
        reRender={() => {
          props.reRender()
        }}
        action={async (addBookIds, removeBookIds) => {
          if (addBookIds.length > 0) {
            await unit.addBooks(addBookIds)
          }
          if (removeBookIds.length > 0) {
            await unit.removeBooks(removeBookIds)
          }
          props.reRender()
        }}
      />
    </div>
  }
  const RenderInheritBooks = () => {
    const [unitId, setUnitId] = useState(-1)
    const nasName = 'inheritBookIdsUnit-' + unit.id
    // const [bookIds, setBookIds] = useState([])
    // let selectedBookIds: number[] = []
    const [RIBRid, setRIBRid] = useState(0)
    if (status != Status.inheritBooksFrom) { return null }
    if (unitId === -1) {
      // show unit Selector:
      return <div className='info-box'>
          <RenderUnitSelector
            exclude={[unit.id]}
            selectUnit={(id: number) => {
              setUnitId(id)
            }}
          />
        </div>
    } else {
      // show Book Selector:
      const fUnit = units.get(unitId)
      if (!fUnit) { return null }
      // only take book ids which are: a) not ours and b) available (books with wrong writes shall not be copied!)
      const fUnitBookIds = fUnit.bookIds.filter(b => !unit.bookIds.some(i => i === b) && books.some(s => s.id === b))
      const selectedBookIds = nas.list(nasName)
      return <div className='info-box'>
        <div>
          <button
            className='w3-btn'
            onClick={() => {
              nas.reset(nasName)
              setUnitId(-1)
            }}
          >Andere Gruppe wählen</button>
        </div>
        <div>Folgende Bücher von {fUnit.name} auswählen:</div>
        { selectedBookIds.length > 0 && selectedBookIds.length === fUnitBookIds.length &&
          <button
            className='w3-button w3-ripple'
            onClick={() => {
              nas.reset(nasName)
              setRIBRid(RIBRid + 1)
            }}
          >Alle abwählen</button>
        }
        { selectedBookIds.length < fUnitBookIds.length &&
          <button
            className='w3-button w3-ripple'
            onClick={() => {
              nas.add(nasName, fUnitBookIds)
              setRIBRid(RIBRid + 1)
            }}
          >Alle wählen</button>
        }
        <RenderBookList
          bookIds={selectedBookIds}
          showAll={true}
          reRender={() => {
            setRIBRid(RIBRid + 1)
          }}
          // We only show books that the source has additional to our own books:
          showSelection={fUnitBookIds}
          action={async (addBookIds, removeBookIds) => {
            await unit.addBooks(addBookIds)
              setStatus(Status.ready)
          }}
        />
      </div>
    }
  }
  return <div className={`unit w3-border w3-padding w3-margin w3-shadow w3-row`}>
      <div className='w3-col m1 unitId'>#{unit.id}</div>
      <div
        className='w3-col m3 unitName'
      >
        {status != Status.editName &&
          <span
            className='pointer'
            onClick={() => {
              setDetailView(!showDetailView)
            }}
          >{unit.name}</span>
        }
        {
          status === Status.editName && <InputA
            returnVal={(r) => {
              unit.setName(r)
              setStatus(Status.ready)
            }}
            value={unit.name}
          />
        }
      </div>
      <div className='w3-col m2 unitUserCount'>
        <div>Nutzerzahl: {unit.users.length()}</div>
        <div>Buchanzahl: {unit.bookIds.length}</div>
      </div>
      <div className='w3-col m2 date'>
          Start:
          <DatePicker
            selected={unit.startDate}
            onChange={(date) => {
              unit.setStartDate(date)
              reRender()
            }}
          />
      </div>
      <div className='w3-col m2 date'>
          Ende:
          <DatePicker
            selected={unit.endDate}
            onChange={(date) => {
              unit.setEndDate(date)
              reRender()
            }}
          />
      </div>
      <div className='w3-col m1 functions w3-right'>
        <div className='w3-dropdown-hover'>
          <button
            className={'w3-button'}
    //         onClick={() => setDropDownState(!dropDownOpen)}
          ><Settings /></button>
          <div
            className={`w3-dropdown-content w3-bar-block w3-border`}
            style={{right: 0}}
          >

            <button
              className={dropDownClass}
              onClick={() => setStatus(Status.editName)}
            >Umbenennen</button>
            <button
              className={dropDownClass}
              onClick={() => setDetailView(!showDetailView)}
            >{showDetailView ? 'nur Basisdaten' : 'Details anzeigen'}</button>
            <button
              className={dropDownClass}
              onClick={() => {
                setDetailView(true)
                setStatus(status === Status.editBooks ? Status.ready : Status.editBooks)
              }}
            >{status === Status.editBooks ? 'Bücher editieren fertig' : 'Bücher editieren'}</button>
            <button
              className={dropDownClass}
              onClick={() => {
                setDetailView(true)
                setStatus(status === Status.inheritBooksFrom ? Status.ready : Status.inheritBooksFrom)
              }}
            >{status === Status.inheritBooksFrom ? 'Bücher von anderer Gruppe wählen beenden' : 'Bücher von anderer Gruppe wählen'}</button>
            <button
              className={dropDownClass}
              onClick={() => {
                setDetailView(true)
                setStatus(status === Status.editDescription ? Status.ready : Status.editDescription)
              }}
            >{status === Status.editDescription ? 'Beschreibung bearbeiten beenden' : 'Beschreibung bearbeiten'}</button>
            <button
              className={dropDownClass}
              onClick={() => {
                if (window.confirm(`Modul ${unit.name} wirklich löschen?`)) {
                  units.remove(unit.id).then(() => props.reRender())
                }
              }}
            >Entfernen</button>

          </div>
        </div>
      </div>
      {
        showDetailView &&
        <RenderDetailData />
      }
    </div>
}

const UnitList = () => {
  const [renderCounter, setRenderCounter] = useState(0)
  const [needle, setNeedle] = useState('')
  const reRender = () => { setRenderCounter(renderCounter + 1) }
  const [status, setStatus] = useState(Status.init)
  const [unitType, setUnitType] = useState('event')
  const [expiredFilter, setExpiredFilter] = useState(true)
  const RenderAddUnit = () => {
    const [addStatus, setAddStatus] = useState(Status.init)
    switch(addStatus) {
      case Status.init:
        return <button
            className='good-button'
            onClick={() => setAddStatus(Status.userInput)}
          >+</button>
      case Status.userInput:
        return <>
          <InputA
            placeholder='Neuer Gruppenname'
            autofocus={true}
            returnVal={(r) => {
              if (!r) { return }
              const p = {
                name: r,
                type: unitType
              } as IUnit
              units.add(p).then(() => {
                setAddStatus(Status.init)
                reRender()
              })
            }}
          />
        </>
      default:
        return null
    }
  }
  const RenderTypeDropDown = () => {
    return <select
        value={unitType}
        onChange={(event) => setUnitType(event.target.value)}
      >
      { UnitTypes.map(u => <option
          key={`UnitTypeDDO-${u.key}`}
          value={u.key}
        >
        { u.name }
        </option>
      ) }
    </select>
  }
  // Only load once:
  if (status === Status.init) {
    setStatus(Status.loading)
    console.log('againinit')
    AsyncWaitForAll([
      async () => { units.getFromServer() },
      async () => { books = (await GetBooks() as BookDTO[]).filter((b) => b.right?.value !== 2227) },
    ]).then(() => { setStatus(Status.ready) })
  }
  const RenderExpiredCheckbox = () => {
    // expiredFilter
    const Symbol = expiredFilter ? <CheckSquare className='cIcon' /> : <Square className='cIcon' />
    return <span
        className='pointer w3-margin-right'
        onClick={() => {
          setExpiredFilter(!expiredFilter)
        }}
      >{Symbol} Vergangene Ausblenden</span>

  }
  const now = new Date().getTime()
  return <div className='UnitList'>
    <div className='UnitListHeader'>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <RenderTypeDropDown />
      <InputA
        className='inline'
        returnVal={(needle: string) => {
          setNeedle(needle)
        }}
        value={needle}
        placeholder='Suche'
      />
      <RenderExpiredCheckbox />
      <RenderAddUnit />
    </div>
    {
      status === Status.loading &&
      <Spinner />
    }
    <div className='UnitListContent'>
      {
        units.list(unitType as UnitType, needle).filter(u => !expiredFilter || !u.endDate || u.endDate.getTime() + 86400000 > now).map(u => <RenderUnit
        // units.list().map(u => <RenderUnit
            key={`unit-${u.id}`}
            unit={u}
            reRender={() => reRender()}
          />)
      }
    </div>
  </div>
}

export default UnitList
