import { useState } from 'react'
import Spinner from '../Spinner/Spinner'
import { Square, MinusSquare, CheckSquare } from 'react-feather'
import UnitDTO, { UnitsDTO, UnitType, UnitTypes } from '../../DTO/UnitDTO'
import { AvailableUser } from '../../DTO/UserDTO'
import InputA from '../InputA/InputA'
import './UnitDD.scss'

enum Status {
  init,
  loading,
  ready,
  userInput,
  editName,
}

enum ActiveState {
  inactive,
  active,
  some,
  passive,
}

class Keys {
  public keys: {
    key: number,
    state: ActiveState
  }[] = []
  set(key: number, state?: ActiveState) {
    const k = this.keys.find(k => k.key === key)
    const newState = (state === undefined) ? ActiveState.active : state
    if (k !== undefined) {
      k.state = newState
      return
    }
    this.keys.push({key: key, state: newState})
  }
  unset(key: number) {
    this.keys = this.keys.filter(k => k.key !== key)
  }
  toggle(key: number) {
    const k = this.keys.find(k => k.key === key)
    if (k) {
      switch(k.state) {
        case ActiveState.active:
          k.state = ActiveState.inactive
          return
        case ActiveState.inactive:
        case ActiveState.some:
          k.state = ActiveState.active
          return
        case ActiveState.passive:
          return
      }
    }
    this.keys.push({key: key, state: ActiveState.active})
  }
  public setList(keys: {key: number, state: ActiveState}[]) {
    this.keys = keys
  }
  isActive(key: number) {
    return this.keys.some(k => k.key === key && k.state === ActiveState.active)
  }
  getState(key: number) {
    return this.keys.find(k => k.key === key)?.state || ActiveState.inactive
  }
  getActive() {
    return this.keys.filter(k => k.state === ActiveState.active)
  }
  get(key: number) {
    return this.keys.find(k => k.key === key)
  }
}

/*
const areArrsDiff = (a: AvailableUser[], b: AvailableUser[]): boolean => {
  if (a.length !== b.length) {
    console.log('aad reason 1')
    return true
  }
  for (let i = 0, m = a.length; i < m; i++) {
    const A = a[i]
    const B = b[i]
    if (A.id !== B.id ||
      A.marked !== B.marked ||
      A.unitIds.length !== B.unitIds.length
    ) {
      console.log('aad reason 2')
      return true
    }
    for (let j = 0, n = A.unitIds.length; j < n; j++) {
      if (A.unitIds[j] !== B.unitIds[j]) {
        console.log('aad reason 3')
        return true
      }
    }
  }
  console.log('aad reason same')
  return false
}
*/

const units = new UnitsDTO()
const unitStates = new Keys()
const checkStates = new Keys()

const SetUnitStates = (userStates: AvailableUser[]) => {
  return units.list().map(u => {
    // Find out if it is active, some or none
    const uWithEntry = userStates.filter(s => s.unitIds.indexOf(u.id) > -1)
    const amount = uWithEntry.length
    let state = ActiveState.inactive
    if (amount === userStates.length) {
      state = ActiveState.active
    } else if (amount > 0) {
      state = ActiveState.some
    } else {
      state = ActiveState.inactive
    }
    return {
      key: u.id,
      state: state
    }
  })
}

const UnitDD = (props: {
  selectedKeys: (keys: number[]) => void,
  // setAvailableUser: (f: (users: AvailableUser[]) => void) => void
  availableUsers: AvailableUser[],
  unitTypes: UnitType[],
  setUnitFilter: (id: number) => void,
  pickedUnitFilters: number[],
  triggerReloadData: (id: number[], force: boolean) => void,
  //() => ((keys: number[]) => void)
}) => {
  let userStates: AvailableUser[] = []
  const [renderId, reRender] = useState(0)
  const [status, setStatus] = useState(Status.init)
  const checkboxMode = props.availableUsers.some(u => u.marked)
  userStates = props.availableUsers
  // Set state of units:
  unitStates.setList(SetUnitStates(props.availableUsers))
  checkStates.setList(SetUnitStates(props.availableUsers.filter(u => u.marked)))
  console.log(':unitStates', unitStates)
  console.log(':checkStates', checkStates)
  const RenderCheckbox = (props: {state: ActiveState}) => {
    switch(props.state) {
      case ActiveState.inactive:
        return <Square />
      case ActiveState.some:
        return <MinusSquare />
      case ActiveState.active:
        return <CheckSquare />
    }
    return null
  }

  const RenderUnit = (uProps: {
    unit: UnitDTO,
    // activeState: ActiveState,
    // checkState: ActiveState,
  }) => {
    const checkState = checkStates.getState(uProps.unit.id)
    const activeState = unitStates.getState(uProps.unit.id)
    const stateClassName = () => {
      const pickedFilterClass = props.pickedUnitFilters.some(p => p === uProps.unit.id) ? 'w3-blue ' : ''
      switch(activeState) {
        case ActiveState.inactive:
          return pickedFilterClass + 'inactive w3-text-grey'
        case ActiveState.active:
          return pickedFilterClass + 'active'
        case ActiveState.some:
          return pickedFilterClass + 'some'
      }
      return ''
    }

    return <div
      className={`w3-bar-item w3-button w3-ripple ${stateClassName()}`}>
      {
        checkboxMode &&
        <span
          onClick={async () => {
            // Activate or deativate unit in marked users:
            const markedUsers = userStates.filter(u => u.marked).map(u => u.id)
            switch(checkState) {
              case ActiveState.active:
                if (window.confirm(`Willst Du wirklich ${uProps.unit.name} von den ausgewählten Nutzern entfernen?`)) {
                  // Remove Unit from Users!
                  await uProps.unit.removeUsers(markedUsers)
                } else {
                  return
                }
                break
              default:
                // Add Unit to Users:
                await uProps.unit.addUsers(markedUsers)
                break
            }
            // Reload Parent and reload database!
            props.triggerReloadData(markedUsers, true)

            reRender(renderId + 1)


          }}
        >
          <RenderCheckbox state={checkState} />
        </span>
      }
      <span
        className='unitDDItemName'
        onClick={() => {
          // unitStates.toggle(uProps.unit.id)
          // reRender(renderId + 1)
          if (activeState !== ActiveState.inactive) {
            props.setUnitFilter(uProps.unit.id)
          }
        }}
      >
        {uProps.unit.name}
      </span>
    </div>
  }



  if (status === Status.init) {
    setStatus(Status.loading)
    units.getFromServer().then(() => setStatus(Status.ready) )
  }

  const UnitTypeDD = (tprop: {
    unitType: UnitType
  }) => {
    const unitType = UnitTypes.find(u => u.key === tprop.unitType)
    const unitName = unitType?.name || ''
    const Content = () => {
      switch(status) {
        case Status.loading:
          return <Spinner />
        case Status.ready:
          return <>
          {
            checkboxMode &&
            <InputA
              placeholder={`Neue ${unitName.replace(/Firmen/, 'Firma').replace(/ungen$/, 'ung')}`}
              returnVal={(r: string) => {
                console.log('Neue Gruppe mit Namen ', r)
                units.addNew(r, tprop.unitType, userStates.filter(u => u.marked).map(u => u.id)).then(() => {
                  props.triggerReloadData([], true)
                  reRender(renderId + 1)
                })
              }}
            />
          }
          {
            units.list(tprop.unitType).map(u => <RenderUnit
                key={`unittype-${u.id}`}
                unit={u}
              ></RenderUnit>)
          }
          {
            units.isEmpty() &&
            <div className='w3-bar-item w3-opacity'>Keine Vorhanden</div>
          }
          </>
      }
      return <div></div>
    }
    return <div className={`w3-dropdown-hover unitdd-type ${checkboxMode ? 'checkboxmode' : 'not-checkboxmode'}`}>
      <div className='w3-button w3-border w3-border-blue'>{unitName}</div>
      <div className={`w3-dropdown-content w3-bar-block w3-border`}>
        <Content />
      </div>
    </div>
  }



  return <>
    {
      props.unitTypes.map(u => <UnitTypeDD
          key={`unittypedd-${u}`}
          unitType={u}
        />
      )
    }
  </>
}

export default UnitDD
