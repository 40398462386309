export enum PropType {
  picture,
  text,
  content,
  select,
}

export enum ValueType {
  string,
  text,
}

export type ParagraphPropType = {
  name: string,
  kind: PropType,
  key1: string,
  key2: string,
  value1Type?: PropType,
  value2Type?: PropType,
  options?: {value: string, name: string}[]
}

export type ParagraphTypeType = {
  name: string,
  key: string,
  props: ParagraphPropType[]
}

const ParagraphTypeSelection = {
  name: 'Absatz-Typ',
  kind: PropType.select,
  key1: 'paragraphType',
  key2: '',
  options: [
    {name: 'Standard Absatz 1', value: 'type1'},
    {name: 'Seitenüberschrift', value: 'main-headline'},
    {name: 'Registrierung', value: 'register'},
    {name: 'Login', value: 'login'},
  ]
}

const ParagraphTypes: ParagraphTypeType[] = [
  {
    name: 'Kopfelement',
    key: 'main-headline',
    props: [
      ParagraphTypeSelection,
      {
        name: 'Überschrift',
        kind: PropType.text,
        key1: 'headline',
        key2: '-'
      },
      {
        name: 'Inhalt',
        kind: PropType.content,
        key1: 'content',
        key2: '-',
      },
      {
        name: 'Link Ziel',
        kind: PropType.text,
        key1: 'link',
        key2: 'target',
      },
      {
        name: 'Link Name',
        kind: PropType.text,
        key1: 'link',
        key2: 'content',
      },
      {
        name: 'Bild',
        kind: PropType.picture,
        key1: 'picture',
        key2: 'id'
      },
      {
        name: 'Bild Position',
        kind: PropType.select,
        key1: 'picture',
        key2: 'position',
        options: [
          {name: 'Links', value: 'left'},
          {name: 'Mitte', value: 'center'},
          {name: 'Rechts', value: 'right'},
        ]
      },
    ]
  },
  {
    name: 'Standard Absatz 1',
    key: 'type1',
    props: [
      ParagraphTypeSelection,
      {
        name: 'Überschrift',
        kind: PropType.text,
        key1: 'headline',
        key2: '-'
      },
      {
        name: 'Inhalt',
        kind: PropType.content,
        key1: 'content',
        key2: '-',
      },
      {
        name: 'Link Ziel',
        kind: PropType.text,
        key1: 'link',
        key2: 'target',
      },
      {
        name: 'Link Name',
        kind: PropType.text,
        key1: 'link',
        key2: 'content',
      },
      {
        name: 'Bild',
        kind: PropType.picture,
        key1: 'picture',
        key2: 'id'
      },
      {
        name: 'Bild Position',
        kind: PropType.select,
        key1: 'picture',
        key2: 'position',
        options: [
          {name: 'Links', value: 'left'},
          {name: 'Mitte', value: 'center'},
          {name: 'Rechts', value: 'right'},
        ]
      },
    ]
  },
  {
    name: 'Registrieren',
    key: 'register',
    props: [
      ParagraphTypeSelection,
      {
        name: 'Überschrift',
        kind: PropType.text,
        key1: 'headline',
        key2: '-'
      },
      {
        name: 'Inhalt',
        kind: PropType.content,
        key1: 'content',
        key2: '-',
      },
      {
        name: 'Link Ziel',
        kind: PropType.text,
        key1: 'link',
        key2: 'target',
      },
      {
        name: 'Link Name',
        kind: PropType.text,
        key1: 'link',
        key2: 'content',
      },
      {
        name: 'Element Position',
        kind: PropType.select,
        key1: 'picture',
        key2: 'position',
        options: [
          {name: 'Links', value: 'left'},
          {name: 'Mitte', value: 'center'},
          {name: 'Rechts', value: 'right'},
        ]
      },
    ]
  },
  {
    name: 'Login',
    key: 'login',
    props: [
      ParagraphTypeSelection,
      {
        name: 'Überschrift',
        kind: PropType.text,
        key1: 'headline',
        key2: '-'
      },
      {
        name: 'Inhalt',
        kind: PropType.content,
        key1: 'content',
        key2: '-',
      },
      {
        name: 'Link Ziel',
        kind: PropType.text,
        key1: 'link',
        key2: 'target',
      },
      {
        name: 'Link Name',
        kind: PropType.text,
        key1: 'link',
        key2: 'content',
      },
      {
        name: 'Element Position',
        kind: PropType.select,
        key1: 'picture',
        key2: 'position',
        options: [
          {name: 'Links', value: 'left'},
          {name: 'Mitte', value: 'center'},
          {name: 'Rechts', value: 'right'},
        ]
      },
    ]
  },
]

export const GetParagraphType = (t: string): ParagraphTypeType | undefined => {
  return ParagraphTypes.find(p => p.key === t)
}

export const GetParagraphPropTypes = (t: string) => {
  const p = GetParagraphType(t)
  if (p) {
    return p.props
  }
  return []
}

export const GetAllParagraphTypes = () => {
  return ParagraphTypes
}
