import React from 'react'
import mainservice from '../../services/MainService'
import { Setter } from '../../services/ComService'
import UserDTO, { IUser } from '../../DTO/UserDTO'
import { ContactRules } from './UserPreferences'
import './SetPasswordByToken.scss'

enum Status {
  userentry,
  success,
  fail
}

type FailResponse = {
  description: string,
  status: string
}
type Props = {
  mode?: string
}

type State = {
  field1C: string,
  field2C: string,
  exeption1C: string,
  exeption2C: string,
  editInProgress: boolean,
  status: Status,
  contactRules: number,
  user: UserDTO | null
}

export default class SetPasswordByToken extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      field1C: '',
      field2C: '',
      exeption1C: '',
      exeption2C: '',
      editInProgress: false,
      status: Status.userentry,
      contactRules: 0,
      user: null
    }
  }

  componentDidMount() {
    const sessionHash: string = mainservice.nav.getVal('session') as unknown as string
    console.log('SESSION HASH: ', sessionHash)
    Setter('user/getByToken', {
      token: mainservice.nav.getVal('session')
    }).then((r: IUser | FailResponse | any) => {
      if (!r || (r as FailResponse).status === 'fail') {
        this.setState({status: Status.fail})
        return
      }
      this.setState({user: new UserDTO({...r, ...{sessionHash: sessionHash}})})
    })
  }

  approve() {
    if (!this.state.field1C) {
      this.setState({
        exeption1C: 'Sie müssen ein Passwort festlegen!',
        exeption2C: ''
      })
      return
    }
    if (this.state.field1C.search(/^[a-zA-Z0-9-_!@#$%^&*]+$/) === -1) {
      this.setState({
        exeption1C: '',
        exeption2C: 'Nur die Sonderzeichen -_!@#$%^&* dürfen verwendet werden'
      })
      return
    }
    if (this.state.field1C.length < 8) {
      this.setState({
        exeption1C: 'Das Passwort sollte mindestens aus 6 Zeichen bestehen',
        exeption2C: ''
      })
      return
    }
    if (this.state.field1C != this.state.field2C) {
      this.setState({
        exeption1C: '',
        exeption2C: 'Die Eingaben stimmen nicht überein!'
      })
      return
    }
    this.setState({
      exeption1C: '',
      exeption2C: ''
    })
    const session = mainservice.nav.getVal('session')
    console.log('new Password can be send', this.state.field1C, session)
    Setter('user/setPasswordByToken', {
      wurzel: this.state.field1C,
      token: session,
      contactRules: this.state.contactRules
    }, {retryName: `${this.state.field1C},${session},${this.state.contactRules}`}).then((result: {status: string} | any) => {
      if (result && result.status === 'okay') {
        this.setState({
          status: Status.success
        })
      } else {
        this.setState({
          status: Status.fail
        })
      }
    }).catch(() => {
      this.setState({
        status: Status.fail
      })
    })

  }

  renderUserInput() {
    return <>

      <div className='w3-row'>
        <div className="w3-card-4 lu-dialog w3-col l5 m6 s12">
          <div className="w3-container lu-headline">Passwort setzen</div>
          <div className="w3-container">
            <label>Passwort</label>
            <input
              className='w3-input'
              type='password'
              value={this.state.field1C}
              autoFocus
              onChange={(event) => {
                this.setState({
                  field1C: event.target.value,
                  editInProgress: true
                })
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  this.approve()
                }
              }}
            />
            { this.state.exeption1C &&
               <div className='w3-panel w3-pale-red w3-padding w3-border w3-border-red'>
                { this.state.exeption1C }
               </div>
            }
            <label>Passwort wiederholen</label>
            <input
              className="w3-input"
              type='password'
              value={this.state.field2C}
              onChange={(event) => {
                this.setState({
                  field2C: event.target.value,
                  editInProgress: true
                })
              }}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  this.approve()
                }
              }}
            />
            { this.state.exeption2C &&
               <div className='w3-panel w3-pale-red w3-padding w3-border w3-border-red'>
                { this.state.exeption2C }
               </div>
            }
            <div>
              Du kannst Passwörter mit einer Länge von 8 bis mindestens 12 Zeichen erstellen, die eine Mischung aus Groß- und Kleinbuchstaben, Zahlen und folgende Sonderzeichen -_!@#$%^&* enthalten sollten.
            </div>
            <button
              className='w3-btn w3-ripple login-button'
              onClick={() => this.approve()}
            >ok</button>
          </div>
        </div>
        <div className='w3-col w3-padding l7 m6 s12' style={{marginTop: '10%'}}>
          <p>Hi, schön, dass du da bist.</p>
          <p>
            Hier kannst du dein Passwort für unsere Onlineplattform "Leadership Essentials" setzen.<br />
            Nach dem OK bekommst du eine Bestätigungsmail.<br />
            Sollte diese E-Mail nicht innerhalb der nächsten 5 Minuten da sein, checke deinen SPAM-Ordner.<br />
            Folge dem Link in der Mail um dich anzumelden.<br />
          </p>
          <p>Viele Grüße vom Team lubbers 😉</p>
        </div>
        <div className='w3-col s12'>
          <h2>Einstellungen</h2>
          {
            this.state.user &&
            <ContactRules user={this.state.user} />
          }
        </div>
      </div>
    </>
  }

  renderSuccess() {
    return <div className='primary-box'>
      <p>
        Dein Passwort wurde gespeichert. Du kannst dich nun mit deiner E-Mail und dem Passwort einloggen.
      </p>
      <a href='/view=login'><button>Zurück zur Loginseite</button></a>
    </div>
  }

  renderFail() {
    return <div className='warning-box'>
      <p>
        Der Registrierungslink scheint abgelaufen zu sein.
      </p>
      <a href='/view=login'><button className='primary-button'>Zurück zur Loginseite</button></a>
    </div>
  }

  render() {
    return <div className='SetPasswordByToken'>
      {
        this.state.status === Status.userentry &&
        this.renderUserInput()
      }
      {
        this.state.status === Status.success &&
        this.renderSuccess()
      }
      {
        this.state.status === Status.fail &&
        this.renderFail()
      }
    </div>
  }
}
