import { useState, useEffect } from "react"
import PreferenceService, {PreferenceDTO, PreferenceType, PreferenceValue} from '../../DTO/PreferenceDTO'
import Spinner from '../Spinner/Spinner'
import mainservice from "../../services/MainService"
import DatePicker from 'react-datepicker'
import JoditReact from "jodit-react"
// import 'jodit/build/jodit.min.css'
import "react-datepicker/dist/react-datepicker.css"
import './Preferences.scss'

enum Status {
  loading,
  ready
}

const RenderValueInput = (props: {
  value: PreferenceValue,
  preference: PreferenceDTO,
  reRenderParent: () => void,
  showDates?: boolean,
}) => {
  const p = props.preference
  const v = props.value
  const [rerender, setRerender] = useState(0)
  const withDates = props.showDates === true
  const [value, setValue] = useState(v.value1)
  let className = 'w3-col s1'
  if (p.type === PreferenceType.text) {
    className = 'w3-col s12'
  }
  const showButton = () => {
    switch(p.type) {
      case PreferenceType.boolean:
        if (value === '1') {
          return <button
            className='w3-btn w3-green w3-left'
            onClick={async () => {
              const newValue = await p.setValue(v.id  || -1, '0')
              setValue(newValue)
            }}
          >Ja</button>
        } else {
          return <button
            className='w3-btn w3-red w3-left'
            onClick={async () => {
              const newValue = await p.setValue(v.id || -1, '1')
              setValue(newValue)
            }}
          >Nein</button>
        }
      case PreferenceType.text:
        return <div>
            <JoditReact
            onChange={(c) => {
              setValue(c)
            }}
            value={value || ''}
            />
            <button
              className={`w3-btn ${v.value1 == value ? '': 'w3-green'}`}
              onClick={async () => {
                v.value1 = value
                await p.setValue(v.id || -1, value)
                setRerender(rerender + 1)
              }}
            >Speichern</button>
          </div>
      default:
        return <div>Typ noch nicht definiert!</div>
    }
  }
  if (withDates) {
    return <div className='preference-item-value-item w3-row w3-center w3-card w3-margin w3-padding'>
      <div
        className='w3-col s1'
      >
        <button
          className='w3-show-inline-block w3-red w3-button'
          onClick={async () => {
            await p.removeValue(v.id || -1)
            props.reRenderParent()
          }}
        >X</button>
      </div>
      <div className='w3-col s2 w3-display-container'>
        <DatePicker
          selected={v.start || null}
          onChange={async (date) => {
            await p.saveDate(v.id || -1, date, 'start')
            props.reRenderParent()
          }}
          onBlur={async (date) => {
            await p.saveValue(v.id || -1)
            props.reRenderParent()
          }}
        />
      </div>
      <div className='w3-col s2 w3-display-container'>
        <DatePicker
          selected={v.end || null}
          onChange={async (date) => {
            await p.saveDate(v.id || -1, date, 'end')
            props.reRenderParent()
          }}
          onBlur={async () => {
            await p.saveValue(v.id || -1)
            props.reRenderParent()
          }}
        />
      </div>
      <div className='w3-col s1 w3-display-container'>
        <button
          className='w3-show-inline-block w3-yellow w3-button'
          title='Enddatum entfernen (open end)'
          onClick={async () => {
            await p.saveDate(v.id || -1, null, 'end')
            props.reRenderParent()
          }}
        >&times;</button>
      </div>
      <div className={className}>
        { showButton() }
      </div>
    </div>
  }
  return <div className='w3-container w3-margin-bottom'>
    { showButton() }
  </div>
}

const RenderPreference = (props: {
  preference: PreferenceDTO
}) => {
  const p = props.preference
  const [renderCounter, setRenderCounter] = useState(0)
  return <div className='preference-item w3-border-bottom'>
    <h2>{p.name}</h2>
    {
      p.description &&
      <div
        className={`description w3-padding `}
        dangerouslySetInnerHTML={{__html: p.description}}
      ></div>
    }
    <button
      className='w3-btn w3-green w3-right'
      onClick={() => {
        p.initNewValue()
        setRenderCounter(renderCounter + 1)
      }}
    >+</button>
    <RenderValueInput
      value={p.getDefaultValue()}
      preference={p}
      reRenderParent={() => setRenderCounter(renderCounter + 1)}
    />
    {
      p.getValues().map((v, i) => <RenderValueInput
        key={`${i}-value-id-${v.id}`}
        value={v}
        preference={p}
        showDates={true}
        reRenderParent={() => setRenderCounter(renderCounter + 1)}
      />)
    }
    
  </div>
}

export default function Preferences() {
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    PreferenceService.init().then(() => {
      setStatus(Status.ready)
    })
  }, [])
  const RenderContent = () => {
    switch(status) {
      case Status.loading:
        return <Spinner />
      case Status.ready:
        return PreferenceService.list().map(p => <RenderPreference
            key={`pref-${p.key1}-${p.key2}`}
            preference={p}
          />)
    }
  }
  return <div className='w3-container scrollY h100'>
    <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
    <h1>Einstellungen</h1>
    { RenderContent() }
  </div>
}