import { useState, useEffect } from 'react'
import { cart, Book } from '../../DTO/ShopDTO'
import UserDashMainMenu from '../User/UserDashMainMenu'
import { Trash } from 'react-feather'
import mainservice from '../../services/MainService'
import Spinner from '../Spinner/Spinner'
import './cart.scss'
import BackButton from '../User/BackButton'

enum Status {
  loading,
  ready,
  choosePaymentMethod,
  paymentManualTransferSuccess,
}

const ClassDescriptionColumn = 'w3-col s9'
const ClassAmountColumn = 'w3-col s2 w3-right-align padding-right'
const ClassActionColumn = 'w3-col s1'



const RenderPaymentManualTransferSuccess = () => {
  // - Set cart to "checked out"
  // - Display orders for User:
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    cart.checkout('manualTransfer').then(() => {
      console.log('RenderCartGetData got data')
      setStatus(Status.ready)
    })
  }, []) // <-- empty array, or this will happen on every rerender!
  switch (status) {
    case Status.loading:
      return <Spinner />
  }
  const displayContent = cart.getAdditional('PaymentManualTransferExplainText')
  
  return <div className='w3-container w3-auto' dangerouslySetInnerHTML={{__html: displayContent}}></div>
}

const ChoosePaymentMethod = (props: {
  setStatus: (status: Status) => void
}) => {
  const methods = [
    {
      name: 'Überweisung',
      onClick: () => {
        props.setStatus(Status.paymentManualTransferSuccess)
      }
    }
  ]
  return <div
    className='w3-row w3-container w3-center'
  >
    <p>Wähle eine Zahlungsart:</p>
    {
      methods.map((m, index) => <button
        key={`${index}`}
        className={`w3-button w3-col s4 w3-card-4 w3-padding minHeight100`}
        onClick={() => {
          m.onClick()
        }}
      >
        {m.name}
      </button>
      )
    }
  </div>
}

const RenderBook = (props: {
  book: Book,
  reRender: () => void
}) => {
  const book = props.book
  const [show, setShow] = useState(true)
  if (!show) { return null }
  return <div className='w3-row w3-padding w3-border-bottom'>
      <div className={ClassDescriptionColumn}>
        {book.name}
      </div>
      <div className={ClassAmountColumn}>
        {cart.renderBookPrice(book.id)}
      </div>
      <div className={ClassActionColumn}>
        <Trash
          className='pointer'
          onClick={async () => {
            console.log('trash!')
            setShow(false)
            await cart.removeBooks([book.id])
            props.reRender()
            mainservice.broadcast('bookAddedToCart', book.id)
          }}
        />
      </div>
    </div>
}

const RenderCart = () => {
  const [status, setStatus] = useState(Status.loading)
  const [renderCounter, setRenderCounter] = useState(0)
  useEffect(() => {
    console.log('RenderCartGetData')
    cart.getFromServer().then(() => {
      console.log('RenderCartGetData got data')
      setStatus(Status.ready)
    })
  }, []) // <-- empty array, or this will happen on every rerender!
  switch(status) {
    case Status.loading:
      return <Spinner />
    case Status.choosePaymentMethod:
      return <ChoosePaymentMethod setStatus={(status: Status) => {
        setStatus(status)
      }}/>
    case Status.paymentManualTransferSuccess:
        return <RenderPaymentManualTransferSuccess />
  }
  const footerData = [
    {
      left: 'Summe',
      right: cart.totalUser(),
      className: ''
    },
    {
      left: `zzg. Umsatzstauer ${cart.tax} %`,
      right: cart.taxToPayAmount(),
      className: ''
    },
    {
      left: `Rechnungsbetrag`,
      right: cart.totalWithTax(),
      className: 'bold'
    },
  ]
  return <div className={`w3-container w3-auto`}>
    {
      cart.listCart().length === 0 &&
      <div className='w3-center w3-margin w3-padding'>Der Einkaufswagen ist leer.</div>
    }
    {
      cart.listCart().map(book => <RenderBook
        key={`cart-item-book-${book.id}`}
        book={book}
        reRender={() => {
          setRenderCounter(renderCounter + 1)
        }}
      />)
    }
    {
      footerData.map((df, index) => <div
          key={`cart-footer-data-${index}`}
          className={`w3-row w3-padding ${df.className}`}
        >
        <div className={`${ClassDescriptionColumn} w3-right-align`}>{df.left}</div>
        <div className={ClassAmountColumn}>{df.right}</div>
      </div>)
    }
    {
      cart.listCart().length > 0 &&
      <>
        <button
          className='secondary-button'
          onClick={async () => {
            await cart.trashCart()
            setRenderCounter(renderCounter + 1)
            mainservice.broadcast('cartLoaded', 0)
          }}
        >Einkaufswagen löschen</button>
        <button
          className='primary-button'
          onClick={async () => {
            setStatus(Status.choosePaymentMethod)
          }}
        >Bezahlen</button>
      </>
    }
    
  </div>
}

const Cart = () => {
  const backName = mainservice.historyBackName()
  return <>
    <UserDashMainMenu />
    <div className='w3-container w3-auto'>
      <h1>Einkaufswagen</h1>
    </div>
    <RenderCart />
    <div className='w3-container w3-auto w3-margin-top'>
      <BackButton className='w3-margin' tag='a'/>

      <a
        href='/'
        className='w3-margin'
        onClick={(e) => {
          e.preventDefault()
          mainservice.navTo([['view', 'userCarts']])
        }}
      >Verlauf</a>
      
    </div>
  </>
}

export default Cart
