import React, {useState} from 'react'
import Spinner from '../Spinner/Spinner'
import { Getter, Setter } from '../../services/ComService'
import mainservice from '../../services/MainService'

enum Status {
  start,
  loading,
  success,
  error
}

function UserEndorse() {
  const [status, setStatus] = useState(Status.start)
  const session = mainservice.nav.getVal('session')
  const content = () => {
    switch(status) {
      case Status.start:
        return <div className='w3-container'>
            <div className='lu-headline'>Registrieren bestätigen</div>
            <p>Hiermit bestätigst du, dass du dich mit deinen Daten auf unserer Plattform registrieren möchtest.</p>
            <button
              className='w3-btn w3-ripple register-button'
              onClick={async () => {
                setStatus(Status.loading)
                const result: {status: string} | any = await Setter('user/endorse', {
                  hash: session
                }, {retryName: `${session}`})
                if (result && result.status === 'okay') {
                  setStatus(Status.success)
                  return
                }
                setStatus(Status.error)
              }}
            >Registrierung abschließen</button>
          </div>
      case Status.loading:
        return <div className='w3-container'>
            <div className='lu-headline'>Registrieren bestätigen</div>
            <p>Wir bestätigen Deine Anfrage.</p>
            <p className='w3-center'><Spinner
            mini={true}
            sizeClass='normalSize'
            /></p>
          </div>
      case Status.success:
        return <div className='w3-container'>
            <div className='lu-headline'>Die Registrierung ist bei uns eingegangen</div>
            <p>Vielen Dank für dein Vertrauen.</p>
            <p className='w3-center fontSize8'>👍</p>
            <p>Sobald wir deinen Account freigeschaltet haben, bekommst du von uns eine Mail.</p>
            <p className='w3-center fontSize8'>⏳</p>
          </div>
      case Status.error:
        return <div className='w3-container'>
            <div className='lu-headline'>Der Link ist ungültig</div>
            <p className='w3-center fontSize8'>🙁</p>
            <p>Versuch Dich erneut zu registrieren oder melde Dich bei uns unter <a href="mailto:digital@lubbers.de">digital@lubbers.de</a></p>
          </div>
    }
  }
  return <div className='w3-row'>
    <div className="w3-col l4 m3 s0">&nbsp;</div>
    <div className='w3-card-4 lu-dialog w3-col l4 m6 s12'>
      { content() }
    </div>
  </div>
}

export default UserEndorse
