import { Getter } from "../services/ComService";
import WidgetAdminDTO from "./WidgetAdminDTO";
import WidgetsDTO from "./WidgetsDTO";

export default class WidgetsAdminDTO extends WidgetsDTO {
  widgets: WidgetAdminDTO[] = []
  public SingleDTO = WidgetAdminDTO
  async getFromServer() {
    const result = await Getter('admin/widgets')
    if (!result) { return }
    this.init({
      widgets: result.data
    })
  }

  getAll() {
    return this.widgets
  }
}