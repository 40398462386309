export type CheckItem = {
  id: number,
  inOut: -1 | 1
}

export default class CheckManager {
  arr: CheckItem[] = []
  get (id: number) {
    return this.arr.find(a => a.id === id)
  }
  set (id: number) {
    const item = this.get(id)
    if (item) {
      item.inOut = 1
      return
    }
    this.arr.push({
      id: id,
      inOut: 1
    })
  }
  unset (id: number) {
    const item = this.get(id)
    if (item) {
      item.inOut = -1
      return
    }
    this.arr.push({
      id: id,
      inOut: -1
    })
  }
  getVal (id: number): number {
    const item = this.get(id)
    if (item) {return item.inOut}
    return 0
  }
  changed (): boolean {
    return this.arr.length > 0
  }
  getSet(): number[] {
    return this.arr.filter(a => a.inOut === 1).map(a => a.id)
  }
  getUnset(): number[] {
    return this.arr.filter(a => a.inOut === -1).map(a => a.id)
  }
}