import config from '../config.json'
import BookDTO from './BookDTO'
import DoublePageDTO from './DoublePageDTO'
import {Getter, Setter} from '../services/ComService'
import {parseStringToServer} from '../services/TextConverter'
import { EffectiveRatingNumberic } from '../Models/Evaluation1/Effective'
import { FlexRating } from '../Models/Evaluation1/Flexible'

export type SessionItem = {
  id: number,
  key1: string,
  key2: string,
  value1: string,
  value2: string,
  date: {date: 'string'},
  email?: string,
}

export type Answer = {
  content: string,
  className: string,
  letter: string,
  selected: boolean,
  result: string,
  consistency: string
}

export type CoachRowData = {
  question: string,
  answers: Answer[],
  surveypart: string,
  analyse: string,
  pageElementId: number,
  date: Date,
  gotDate: boolean,
  effectiveQualityNumber: number,
  flexibleQualityNumber: number,
  timeTaken? : number,
  origSortNumber: number
}

export default class SessionDTO {
  hash: string
  sessionData: SessionItem[] = []
  book: BookDTO
  bookId: number = -1
  coachData: CoachRowData[] = []
  sessionIds: string = ''
  constructor(o: ISession) {
    this.hash = o.hash
    this.bookId = o.bookId || -1
    this.book = new BookDTO({id: 0, name: ''})
    if (o.sessionData) {
      this.sessionData = o.sessionData
    } else {
      this.getFromServer()
    }
  }

  async getFromServer() {
    // Reset Data - because we really want to see actual data:
    this.sessionData = []
    this.book = new BookDTO({id: 0, name: ''})
    console.log('getfromserver')
    if (!this.hash) { return }
    if (this.bookId > -1) {
      await this.getBookSessionByMail()
      return
    }
    const sessionOk = await this.getSessionFromServer()
    console.log('sessionOk', sessionOk)
    if (!sessionOk) { return }
    await this.getBookFromServer()
  }

  async getSessionFromServer() {
    const sResponse = await fetch(`${config.apiPrefix}session/${this.hash}`)
    const sResponseJson = await sResponse.json()
    if (!sResponseJson.error) {
      this.sessionData = sResponseJson.data
      return true
    }
    return false
  }

  async getBookFromServer() {
    const sResponse = await fetch(`${config.apiPrefix}session/${this.hash}/getBook`)
    const sResponseJson = await sResponse.json()
    if (!sResponseJson.error) {
      this.book = new BookDTO(sResponseJson.book)
      return true
    }
    return false
  }

  async getBookSessionByMail() {
    console.log('getBookFromServer BOOKID ', this.bookId)
    const result: any = await Setter('session/bookandemail', {
      bookId: this.bookId,
      emails: this.hash
    })
    console.log('getBookSessionByMail', result)
    this.sessionData = result.sessionData
    this.sessionIds = result.sessionIds
    this.book = new BookDTO(result.book)
    // this.book = new BookDTO(result.book)
    return false
  }

  async set(hash: string, bookId?: number) {
    if ( bookId !== undefined ) { this.bookId = bookId }
    this.hash = hash
    await this.getFromServer()
    this.generateCoachData()
  }

  generateCoachData(email?: string) {
    let coachData = []
    if (!this.book.doublePages || !this.sessionData) { return }
    // TODO: visit all Double Pages and extract Description and Survey and selected option
    const relevantDoublePages = this.book.doublePages.filter((dP: DoublePageDTO) => {
      return dP.pages.some((p) => {
        return p.pageElements.some((pE) => {
          return pE.elementType.id === 3 // multiplechoice
        })
      })
    })
    coachData = relevantDoublePages.map((dP: DoublePageDTO) => {
      let question: string = ''
      let answers: Answer[] = []
      let surveypart: string = ''
      let analyse: string = ''
      let gotDate: boolean = false
      let date: Date = new Date()
      let pageElementId: number = 0
      dP.pages.forEach((p) => {
        p.pageElements.forEach((pE) => {
          // extract question:
          if (pE.elementType.id === 1 && pE.value2 != 'h1' && pE.value2 != 'h2') {
            question += pE.value1 + ' '
          }
          // extract answers:
          if (pE.elementType.id === 3) {
            pageElementId = pE.id
            surveypart = pE.value2
            // get the answer from the user:
            const userAnswer = this.sessionData.find((uA) => uA.key2 === surveypart && (!email || !uA.email || uA.email === email))
            const answerLetter = (userAnswer) ? userAnswer.value2 : ''
            if (userAnswer && userAnswer.date) {
              date = new Date(userAnswer.date.date)
              gotDate = true
            }
            pE.elementProps.forEach((eP) => {
              // get multiplechoice:
              if (eP.key1 === 'multiplechoice') {
                const mCR = pE.elementProps.find((ePR) => ePR.key1 === 'result' && ePR.key2 === eP.value2) || {value1: ''}
                const mCC = pE.elementProps.find((ePR) => ePR.key1 === 'consistency' && ePR.key2 === eP.value2) || {value1: ''}
                answers.push({
                  content: eP.value1,
                  className: '',
                  letter: eP.value2,
                  selected: (answerLetter === eP.value2),
                  result: mCR.value1,
                  consistency: mCC.value1
                })
              }
              // get analyse:
              if (eP.key1 === 'analyse') {
                analyse = eP.value1
              }
            })
          }
        })
      })
      let result = {
        question: question,
        answers: answers,
        surveypart: surveypart,
        date: date,
        gotDate: gotDate,
        analyse: analyse,
        pageElementId: pageElementId,
        effectiveQualityNumber: this.getEffectiveQualityNumber(surveypart, answers),
        flexibleQualityNumber: this.getFlexibleQualityNumber(surveypart, answers),
        origSortNumber: parseInt(surveypart.replace(/[^0-9]/g, ''), 10)
      }
      return result
    })
    if (email) {
      return coachData || []
    }
    this.coachData = coachData
  }

  public getCoachData(email?: string) {
    if (email) {
      console.log('email specific coach data', this.generateCoachData(email))
      return this.generateCoachData(email) || []
    }
    return this.coachData || []
  }

  private getEffectiveQualityNumber(surveypart: string, answers: Answer[]): number {
    const answer = answers.find((answer) => answer.selected)
    const effectiveQualityNumber = (answer) ?
      EffectiveRatingNumberic(surveypart, answer.letter) :
      10
    return effectiveQualityNumber
  }

  private getFlexibleQualityNumber(surveypart: string, answers: Answer[]): number {
    const answerIndex = answers.findIndex((answer) => answer.selected)
    return FlexRating(surveypart, answerIndex, 'number') as number
  }

  public async addElementProp(elementId: number, key1: string, key2: string, value1: string, value2: string) {
    const value1Parsed = parseStringToServer(value1)
    const value2Parsed = parseStringToServer(value2)
    await Setter(`PageElement/${elementId}/addProp`, {
      key1: key1,
      key2: key2,
      value1: value1Parsed,
      value2: value2Parsed,
    })
    await this.getFromServer()
    this.generateCoachData()
  }
}

export interface ISession {
  hash: string
  sessionData?: any
  book?: BookDTO
  bookId?: number
}
