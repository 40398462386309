import React from 'react'
import BookDTO, {V1, Key2, Key1} from '../../DTO/BookDTO'
import InputA from '../InputA/InputA'
import mainservice from '../../services/MainService'
import './BookSurveyA.scss'

enum Tab {
  survey,
  texts
}

type Props = {
}

type State =  {
  book: BookDTO,
  surveyData: Key1[],
  bookId: number,
  tab: Tab,
  currentKey: string
}


export default class BookSurveyA extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      book: new BookDTO({id: -1, name: ''}),
      surveyData: [],
      bookId: 0,
      tab: Tab.survey,
      currentKey: ''
    }
  }

  async getBookData(bookId: number, self: any) {
    console.log('new book id', bookId)
    const newBook = new BookDTO({id: bookId, name: ''})
    await newBook.getFromServer()
    const sk1s: any = await newBook.getAgregatedSessionData()
    console.log('sk1s', sk1s)
    self.setState({
      surveyData: sk1s,
      book: newBook,
      bookId: bookId
    })
  }

  changeBookId(bookIdString: string, self: any) {
    const bookId = parseInt(bookIdString, 10)
    if (bookId && bookId > 0) {
      self.getBookData(bookId, self)
    }
  }

  sortFunction(va: V1, vb: V1): boolean {
    return (va.value1 < vb.value1)
  }

  renderKey1Data(data: Key1, i: number) {
    return <div key={i}>
      <div className="w3-card">
        <h2>{data.key1}</h2>
        {data.key2s.filter((item) => item.key2.search('survey') > -1).map((item, index) => this.renderKey2Data(item, index))}
      </div>
    </div>
  }

  renderKey2Data(data: Key2, i: number) {
    let sum = 0
    data.v1s.forEach((item: V1) => {
      sum += item.amount
    })

    return <div key={i} className="">
      <div className="w3-card">
      <h3>{data.key2}</h3>
      <div className="BSshowSpoiler">
        Zeige Text
        <div className="BSspoiler" dangerouslySetInnerHTML={{__html: data.userText}}></div>
      </div>
      <table>
      <tbody>
      {data.v1s.sort((va, vb) => (va.value1 > vb.value1) ? 1 : -1).map((item, index) => this.renderValData(item, index, sum))}
      </tbody>
      </table>
    </div>
    </div>
  }

  renderValData(data: V1, i: number, sum: number) {
    return <tr key={i}>
      <td className="w3-border">
        <div>
          {Math.round(data.amount / sum * 100)}%
        </div>
        <div>
          ({data.amount})
        </div>
      </td>
      <td className="w3-border">{data.userText}</td>
    </tr>
  }

  renderSurvey() {
    const data = this.state.surveyData.filter((item) => item.key1.search('survey') > -1)
    return <div>
    {
      data.map((item, index) => this.renderKey1Data(item, index))
    }
    {
      data.length === 0 &&
      <div className='nodata'>Keine Multiple Choice Einträge gefunden</div>
    }
    </div>
  }

  renderTextsSurvey() {
    const textSurvey = this.state.surveyData.find(item => item.key1 === 'userTextInput')
    if (!textSurvey) { return <div className='nodata'>Keine Text Einträge gefunden</div> }
    const keys = textSurvey.key2s
    const key = keys.find(k => k.key2 === this.state.currentKey)
    let results: {value1: string}[] = []
    if (key) {
      results = key.v1s
    }
    return <div>
      {
        keys.map((k, i) => <button className='secondary-button' key={i} onClick={() => this.setState({currentKey: k.key2})}>{k.key2}</button>)
      }
      {
        keys.length === 0 &&
        <div className='nodata'>Keine Text Einträge gefunden</div>
      }
      {
        results.reverse().map((t, i) => <div key={`text-result-${i}`} className='w3-padding w3-border w3-margin'>{t.value1}</div>)
      }
    </div>
    /*
    this.state.surveyData.filter((item) => item.key1.search('userTextInput') > -1).map((item: {key1: string, key2s: {key2: string, v1s: {value1: string}[]}[]}, index) => {
      item.key2s.map((it, in) => this.textKey(it, in))
    })
    */
  }

  render() {
    return <div className="w3-container booksurvey admin-tool">
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <button className='secondary-button' onClick={() => this.setState({tab: Tab.survey})}>MultipleChoice</button>
      <button className='secondary-button' onClick={() => this.setState({tab: Tab.texts})}>Text-Kommentare</button>
      <div className='w3-container w3-center'>
        Buch ID eingeben: <InputA returnVal={this.changeBookId} parent={this} value={this.state.bookId + ''}/>
      </div>
      { this.state.tab === Tab.survey &&
        this.renderSurvey()
      }
      { this.state.tab === Tab.texts &&
        this.renderTextsSurvey()
      }
    </div>
  }
}
