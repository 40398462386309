export default class UserRightsDTO {
  didInit: boolean = false
  genericProps: RightProp[] = []
  tags: RightProp[] = []
  constructor(userRights?: IUserRightsDTO) {
    if (userRights) {
      this.genericProps = userRights.genericProps
      this.tags = userRights.tags
      this.didInit = true
    }
  }

  can (key1: string, key2: string, action: Action, list: RightProp[]) {
    // If we are public - do not filter:
    if (!this.didInit) { return true }
    // If we are logged in - look for our rights:
    const rule = list.find(p => p.key1 === key1 && p.key2 === key2)
    // If we can not find a rule, this means there are no restrictions:
    if (!rule) { return true }
    // If reading allowed - that is good: 
    if (rule[action]) { return true }
    // The final answer: no:
    return false
  }

  // Test if reading allowed
  canRead(key1: string, key2: string) {
    // console.log('canread', key1, key2, 'start')
    const result = this.can(key1, key2, 'read', this.genericProps)
    // console.log('canread', key1, key2, result)
    return result
  }

  // Test if writing allowed
  canWrite(key1: string, key2: string) {
    // console.log('canwrite', key1, key2, 'start')
    const result = this.can(key1, key2, 'write', this.genericProps)
    // console.log('canwrite', key1, key2, result)
    return result
  }

  canSeeTagManager() {
    return this.can('manager', 'list', 'read', this.tags)
  }
}

type Action = 'read' | 'write'

export interface RightProp {
  key1: string,
  key2: string,
  read: boolean,
  write: boolean,
}

export interface IUserRightsDTO {
  genericProps: RightProp[],
  tags: RightProp[],
}
