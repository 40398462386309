import React from 'react'
import SessionDTO, {CoachRowData} from '../../DTO/SessionDTO'
import Spinner from '../Spinner/Spinner'
import InputA from '../InputA/InputA'
import { EffectiveRating } from '../../Models/Evaluation1/Effective'
// import Evaluation1TextGenerator from '../../Models/Evaluation1/TextGenerator'
import Evaluation1Flexible from '../../Models/Evaluation1/Flexible'
import {Edit} from 'react-feather'
import './CoachALegacy.scss'
import { saveAs, FileSaverOptions } from 'file-saver'
import { Save } from 'react-feather'
import mainservice from '../../services/MainService'
import CoachSendSessionToHistory from './CoachSendSessionToHistory'

type Props = {

}

type State = {
  loading: boolean,
  session?: SessionDTO,
  editField: string,
  tabNumber: number
}

class CoachALegacy extends React.Component<Props, State> {
  columnNames = [
    'Bearbeitungszeit',
    'Situationsbeschreibung',
    'Analyse der Situation',
    'Gewählte Option',
    'Beschreibung der Handlungsoption',
    'Bewertung der Handlungsoption',
    'Folgen der Handlungsoption',
    'Konsequenzen Ihres Führungshandelns'
  ]

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: false,
      session: new SessionDTO({hash: ''}),
      editField: '',
      tabNumber: 0
    }
    this.getContent()
  }

  async getContent(fresh?: boolean) {
    // We only need the sessionID to identify the book!
    this.setState({loading: true})
    const sessionId = mainservice.nav.getVal('session') as string
    if (!this.state || !this.state.session) { return }
    await this.state.session.set(sessionId)
    this.setState({loading: false})
  }

  renderRow(cD: CoachRowData, index: number) {
    let timeTaken = '-'
    if (cD.date &&
      this.state.session &&
      this.state.session.coachData[index + 1] &&
      this.state.session.coachData[index + 1].date &&
      this.state.session.coachData[index + 1].date.getTime() - cD.date.getTime() > 0
    ) {
      timeTaken = mainservice.time.convertTimeToHourMinuteSeconds(
        new Date(this.state.session.coachData[index + 1].date.getTime() - cD.date.getTime())
      )
    }
    const span = cD.answers.length
    const answers = cD.answers.map((a, i) => {

      const td = <>
        <td>
          <div className={`item ${a.selected ? ' selected ' : ' '}`}>
            {a.letter}
          </div>
        </td>
        <td>
          {a.content}
        </td>
        <td>
          { EffectiveRating(cD.surveypart, a.letter) }
        </td>
        <td>
          {
            this.state.editField != `${index}-${i}-result` &&
            <>
              <Edit onClick={() => this.setState({editField: `${index}-${i}-result`})}/>
              <div
                dangerouslySetInnerHTML={{__html: a.result}}
              ></div>
            </>
          }
          {
            this.state.editField === `${index}-${i}-result` &&
            <InputA returnVal={(newResult: string, self: any) => {
              self.state.session.addElementProp(cD.pageElementId, 'result', a.letter, newResult, 'misc').then(() => {
                this.setState({editField: ``})
                // self.getContent()
              })
            }} parent={this} value={a.result} type='textArea'/>
          }
        </td>
        <td>
          {
            this.state.editField != `${index}-${i}-consistency` &&
            <>
              <Edit onClick={() => this.setState({editField: `${index}-${i}-consistency`})}/>
              <div
                dangerouslySetInnerHTML={{__html: a.consistency}}
              ></div>
            </>
          }
          {
            this.state.editField === `${index}-${i}-consistency` &&
            <InputA returnVal={(newResult: string, self: any) => {
              self.state.session.addElementProp(cD.pageElementId, 'consistency', a.letter, newResult, 'misc').then(() => {
                this.setState({editField: ``})
                // self.getContent()
              })
            }} parent={this} value={a.consistency} type='textArea'/>
          }
        </td>
        </>
      if (i === 0) {
        return td
      }
      return <tr key={i}>{td}</tr>
    })
    return <>
      <tr key={index}>
        <td rowSpan={span}>
          {timeTaken}
        </td>
        <td rowSpan={span}>
          {cD.question}
        </td>
        <td rowSpan={span}>
          {
            this.state.editField != `${index}-analyse` &&
            <>
              <Edit onClick={() => this.setState({editField: `${index}-analyse`})}/>
              <div>
                {cD.analyse}
              </div>
            </>
          }
          {
            this.state.editField === `${index}-analyse` &&
            <InputA returnVal={(newAnalyse: string, self: any) => {
              self.state.session.addElementProp(cD.pageElementId, 'analyse', 'misc', newAnalyse, 'misc').then(() => {
                this.setState({editField: ``})
                // self.getContent()
              })
            }} parent={this} value={cD.analyse} type='textArea'/>
          }
        </td>
          {
            answers[0]
          }

      </tr>
      {
        answers.slice(1)
      }
    </>
  }

  async changeSession(sessionHash: string, self: any) {
    mainservice.nav.setVal('session', sessionHash)
    await self.getContent()
  }

  renderTexts() {
    let flexible = new Evaluation1Flexible((this.state.session) ? this.state.session.sessionData: [])
    const pBC = flexible.pointsByCategory
    const texts = flexible.getAllTextAndValidIndex()
    return <>
      <table>
        <caption>Erreichte Punkte</caption>
        <thead><tr><th>s1</th><th>s2</th><th>s3</th><th>s4</th></tr></thead>
        <tbody><tr><td>{pBC[0]}</td><td>{pBC[1]}</td><td>{pBC[2]}</td><td>{pBC[3]}</td></tr></tbody>
      </table>
      <h3>Auswahl des Antworttextes</h3>
      <div>
        {
          texts.texts.map((text, index) => {
            return <div className={(texts.index === index) ? 'w3-yellow w3-border w3-padding w3-margin' : 'w3-border w3-padding w3-margin'}>
              Bedingungen
              <code>
                {
                  text.logic
                }
              </code>
              <h3>Text</h3>
              <div>{text.text}</div>
            </div>
          })
        }
      </div>
    </>
  }

  renderCSVRow(cD: CoachRowData, index: number) {
    let out = ''
    let timeTaken = '-'
    if (cD.date &&
      this.state.session?.coachData[index + 1] &&
      this.state.session?.coachData[index + 1].date &&
      this.state.session.coachData[index + 1].date.getTime() - cD.date.getTime() > 0
    ) {
      timeTaken = mainservice.time.convertTimeToHourMinuteSeconds(
        new Date(this.state.session.coachData[index + 1].date.getTime() - cD.date.getTime())
      )
    }
    const correctAnswer = cD.answers.find((a) => a.selected)
    out += [
      timeTaken,
      cD.question,
      cD.analyse,
      correctAnswer?.letter || '-',
      correctAnswer?.content || '-',
      EffectiveRating(cD.surveypart, correctAnswer?.letter || '-'),
      correctAnswer?.result || '-',
      correctAnswer?.consistency || '-',
    ].map((o) => `"${o.replace(';', '\;').replace('"', '\"').replace(/<[^>]*>/g, ' ')}"`).join(';')
    return out
  }

  generateCSV() {
    let out = ''
    out += this.columnNames.map((cN) => `"${cN}"`).join(';') + '\n'
    if (this.state.session) {
      out += this.state.session.coachData.map((cD, index) => this.renderCSVRow(cD, index)).join('\n')
    }
    return out
  }

  downloadCSV() {
    const blob = new Blob([this.generateCSV()], {type: 'text/plain'})
    // saveAs(blob, mainservice.nav.getVal('session') + '.csv')
    const options: FileSaverOptions = {
      autoBom: true
    }
    saveAs(blob, mainservice.nav.getVal('session') + '.csv', options)
  }

  render() {
    return <div className='coachPage admin-tool'>
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <button className='secondary-button' onClick={() => this.setState({tabNumber: 0})}>Fragen</button>
      <button className='secondary-button' onClick={() => this.setState({tabNumber: 1})}>Antworten</button>
      <button className='secondary-button' onClick={() => this.downloadCSV()}><Save />CSV Export</button>
      <div className='w3-container w3-center'>
        Session ID eingeben: <InputA returnVal={this.changeSession} parent={this} value={mainservice.nav.getVal('session') as string}/>
        <CoachSendSessionToHistory sessionHash={this.state.session?.hash || ''} />
      </div>
      {
        this.state.loading && <Spinner key={'bookSpinner'} />
      }
      {
        !this.state.loading && this.state.session && this.state.session.coachData.length === 0 &&
        <div>
          <div className="warning-box">
            {this.state.session ? 'Bitte eine Session ID eingeben' : 'Session ID ungültig'}
          </div>
        </div>
      }
      {
        !this.state.loading && this.state.tabNumber === 0 && this.state.session && this.state.session.coachData.length > 0 &&
        <table>
          <thead>
          <tr>
            {
              this.columnNames.map((cN, index) => <th key={index}>{cN}</th>)
            }
          </tr>
          </thead>
          <tbody>
            {
              this.state.session &&
              this.state.session.coachData.map((cD, index) => this.renderRow(cD, index))
            }
          </tbody>
        </table>
      }
      {
        !this.state.loading && this.state.tabNumber === 1 &&
        this.renderTexts()
      }
    </div>
  }
}

export default CoachALegacy
