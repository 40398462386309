import React from 'react'
import {Getter} from '../../services/ComService'
import BookDTO, {IBook} from '../../DTO/BookDTO'
import RightDTO, {IRight} from '../../DTO/RightDTO'
import Spinner from '../Spinner/Spinner'
import InputA from '../InputA/InputA'
import { Trash, Edit, Link, Edit3 } from 'react-feather'
import BookAdminPropSwitcher from '../Book/BookAdminPropSwitcher'
import mainservice from '../../services/MainService'

import './Booklist.scss'

enum EditorKind {
  text,
  bookTile
}

type EditorData = {
  headline: string,
  kind: EditorKind,
  // key1: string,
  // key2: string,
  value: string
  cb: (v: string, self: any) => void
}

enum DisplayState {
  books,
  standardPages
}

type Props = {
  selectFkt: any
}

type State = {
  loading: boolean,
  booklist: BookDTO[],
  rightList: RightDTO[]
  editBookId: number,
  editBookNameStatus: string,
  editor: EditorData,
  displayState: DisplayState
}

class Booklist extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      loading: true,
      booklist: [],
      rightList: [],
      editBookId: -1,
      editBookNameStatus: '',
      editor: {} as EditorData,
      displayState: DisplayState.books
    }
  }

  getContent() {
    let bookMap = new Map()
    Getter('books').then((r: {books: IBook[], rights: IRight[]}) => {
      this.setState({
        loading: false,
        booklist: r.books.filter((b) => {
          if (!bookMap.has(b.id)) {
            bookMap.set(b.id, true)
            return true
          }
          return false
        }).map((b) => new BookDTO(b)),
        rightList: r.rights.map((r) => new RightDTO(r)),
        editBookId: -1
      })
    })
  }

  componentDidMount() {
    this.getContent()
  }

  renderAdminAction(book: BookDTO) {
    if (mainservice.isAdmin() <= 1) { return null }
    return <>
      {
        [
          {
            name: 'UmbruchName',
            html: <Edit3 />,
            fkt: () => {
              this.setState({
                editBookId: book.id,
                editor: {
                  headline: 'UmbruchName',
                  kind: EditorKind.bookTile,
                  cb: (v: string, self) => {
                    book.setHyphenName(v).then(() => self.getContent())
                  },
                  value: book.hyphenName || book.name
                }
              })
            }
          },
          {
            name: 'Umbenennen',
            html: <Edit />,
            fkt: () => {
              this.setState({
                editBookId: book.id,
                editor: {
                  headline: 'Umbenennen',
                  kind: EditorKind.text,
                  cb: (v: string, self) => {
                    book.setName(v).then(() => self.getContent())
                  },
                  value: book.name
                }
              })
            }
          },
          {
            name: 'Url Shorthand',
            html: <Link />,
            fkt: () => {
              this.setState({
                editBookId: book.id,
                editor: {
                  headline: 'Url Shorthand setzen',
                  kind: EditorKind.text,
                  cb: (v: string, self) => {
                    book.addProp({
                      id: -1,
                      key1: 'url',
                      key2: 'shorthand',
                      value1: v,
                      value2: '-'
                    }).then(() => self.getContent())
                  },
                  value: book.getPropVal1('url', 'shorthand', '-')
                }
              })
            }
          },
          {
            name: 'Löschen',
            html: <Trash />,
            fkt: () => {
              if (!window.confirm('Buch wirklich löschen?')) { return }
              book.remove().then(() => this.getContent())
            }
          }
        ].map((o, index) => <span
            key={index}
            className='action w3-bar-item'
            onClick={() => o.fkt()}
            title={o.name}
          >
            {o.html}
          </span>
        )
      }
      <div
        className='action w3-bar-item'
      >
        <div>
          <select
          className='action w3-bar-item'
          onChange={(event) => {
            const newRightId = parseInt(event.target.value, 10)
            const right = this.state.rightList.find((r) => r.id === newRightId)
            if (right) {
              book.setRight(right)
              this.forceUpdate()
            }
          }}
          value={book.right.id}
        >
          {
            this.state.rightList.map(
              (r) => <option
                key={r.id}
                value={r.id}
                title={r.description}
              >
                {r.name}
              </option>
            )
          }
        </select>
        </div> 
        <div>
          <InputA
            returnVal={async (v) => {
              await book.setPrice(v)
              this.forceUpdate()
            }}
            value={`${book.getCurrentPriceRender()}`}
            placeholder={`Preis (in Euro)`}
          ></InputA>
        </div>
      </div>
      
      <BookAdminPropSwitcher
        key={`book-layout-` + book.id}
        className='action w3-bar-item'
        parent={book}
        name='Cover SchriftFarbe'
        key1='layout'
        key2='coverFontColor'
        value1Arr={[
          ['standard', 'standard'],
          ['white', 'weiss'],
          ['red', 'rot'],
          ['purple', 'violett'],
          ['blue', 'blau'],
          ['green', 'grün'],
          ['orange', 'orange']
        ]}
        value1Current={book.getPropVal1('layout', 'coverFontColor', 'standard')}
        cb={() => { this.forceUpdate() }}
      />
    </>
  }

  renderGenericEditor() {
    if (this.state.editBookId === -1 || mainservice.isAdmin() <= 1) {
      return <></>
    }
    if (this.state.editor.kind === EditorKind.bookTile) {
      return <div className='genericEditorBackground w3-row'>
        <div className='w3-card-4 w3-dark-grey dialogBox'>
          <div className='dialogHeadline'>
            {this.state.editor.headline}
          </div>
          <div className='dialogContext tileEdit'>
            <InputA
              returnVal={this.state.editor.cb}
              value={this.state.editor.value}
              parent={this}
              cancel={(self: any) => {self.setState({editBookId: -1})}}
              type='textArea'
            />
          </div>
        </div>
      </div>
    }
    return <div className='genericEditorBackground w3-row'>
      <div className='w3-card-4 w3-dark-grey dialogBox'>
        <div className='dialogHeadline'>
          {this.state.editor.headline}
        </div>
        <div className='dialogContext'>
          <InputA
            returnVal={this.state.editor.cb}
            value={this.state.editor.value}
            parent={this}
            cancel={(self: any) => {self.setState({editBookId: -1})}}
          />
        </div>
      </div>
    </div>
  }

  renderBook(book: BookDTO) {
    return <li
      key={'book' + book.id}
      className="w3-bar hoverHighLight"
      title={`book id ${book.id}`}
    >
      <div className="avatar w3-bar-item w3-circle bg-color-1">
        <div
          className='bookPicture'
          style={{backgroundImage: book.getCssBGPicture() || 'url()'}}
        >
        </div>
      </div>
      <div className="w3-bar-item">
        <div className="w3-large pointer" onClick={() => this.props.selectFkt(book.id)}>
          {book.name}
        </div>
        <div> ({book.getPropVal1('url', 'shorthand', '-')})</div>
      </div>
      { this.renderAdminAction(book) }
    </li>
  }

  renderTabs() {
    const bs = [
      {
        name: 'Bücher',
        key: DisplayState.books
      },
      {
        name: 'Kollektionen und geheime Bücher',
        key: DisplayState.standardPages
      },
    ]
    return bs.map((b) => <button
        key={`book-type-tab-${b.key}`}
        className={`${this.state.displayState === b.key ? 'primary-button' : 'secondary-button'}`}
        onClick={() => this.setState({displayState: b.key})}
      >{b.name}</button>)
  }

  render() {
    const isAdmin = mainservice.isAdmin()
    const books = this.state.booklist.filter((b: BookDTO) => {
      if (
        this.state.displayState === DisplayState.books && b.right.value !== 2227 ||
        this.state.displayState === DisplayState.standardPages && b.right.value === 2227
      ) {
        return true
      }
      return false
    }).map((b: BookDTO) => this.renderBook(b))
    const newBook = new BookDTO({id: -2, name: ''})
    return <div className='booklist admin-tool'>
    <button
      onClick={() => mainservice.navTo([['view', 'adminlogin']])}
      className='secondary-button'
    >&larr; Dashboard</button>
      { this.renderTabs() }
      { this.renderGenericEditor() }
      <div className="w3-container header">
        <h2>Bücher</h2>
      </div>
      {
        this.state.loading && <Spinner />
      }
      <ul className="w3-ul w3-card-4 w3-margin">
      {
        books
      }
      </ul>

      {
        isAdmin > 1 && this.state.editBookId !== -2 &&
        <div className="w3-bar-item">
          <button className="w3-btn w3-green" onClick={() => this.setState({
            editBookId: -2,
            editor: {
              headline: 'Neues Buch anlegen',
              kind: EditorKind.text,
              cb: (v: string, self) => {
                newBook.setName(v).then(() => self.getContent())
              },
              value: newBook.name
            }
          })
        }>Hinzufügen</button>
        </div>
      }
    </div>
  }
}

export default Booklist
