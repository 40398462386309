import WidgetDTO, { IWidget } from "./WidgetDTO"

export default class WidgetsDTO {
  private dataSrc = 'user/widgets'
  public  SingleDTO = WidgetDTO
  // private ISingleDTO = IWidget
  widgets: WidgetDTO[] = []

  constructor(data?: IWidgets) {
    this.init(data)
  }

  init(data?: IWidgets) {
    if (data?.widgets) {
      this.widgets = data.widgets.map(w => new this.SingleDTO(w))
    }
  }

  async getFromServer() {
    
  }

  getAll() {
    return this.widgets
  }
}

export interface IWidgets {
  widgets: IWidget[]
}