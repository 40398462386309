import { useState, useEffect } from "react"
import { Setter, Getter } from '../../services/ComService'
import CartDTO, { cart, CartStatus, ICart, TranslateCartStatus } from '../../DTO/ShopDTO'
import Spinner from '../Spinner/Spinner'
import mainservice from "../../services/MainService"
// import {SanitizeDate} from '../services/DateTime'

enum Status {
  loading,
  ready,
  more,
}

let carts: CartDTO[] = []

export default function Carts() {
  const [status, setStatus] = useState(Status.loading)
  const [renderCounter, setRenderCounter] = useState(0)
  useEffect(() => {
    Getter('shop/getCarts').then(result => {
      console.log('result', result)
      carts = result.map((r: ICart) => new CartDTO(r, true))
      setStatus(Status.ready)
    })
    Getter('shop/trashOldCarts') // tidy up!
  }, [])
  if (status === Status.loading) {
    return <div className='w3-container'>
      
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <Spinner />
    </div>
  }
  if (carts.length === 0) {
    return <div className="w3-container">
      <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
      <div className='info-box'>Zur Zeit keine Einkaufwagen im Status CHECKOUT (8)</div>
    </div>
  }
  return <div className='w3-container h100 scrollY'>
    <button className='secondary-button' onClick={() => mainservice.navTo([['view', 'adminlogin']])}>&larr; Dashboard</button>
    {
      carts.map((c, index) => <RenderCart
          key={`${index}-${c.hash}`}
          cart={c}
          rerender={() => setRenderCounter(renderCounter + 1)}
        />)
    }
  </div>
}

function RenderCart(props: {cart: CartDTO, rerender: () => void}) {
  const cart = props.cart
  const rerenderParent = props.rerender
  const [status, setStatus] = useState(Status.ready)
  const cols: {
    value?: string,
    className: string,
    onClick?: () => void,
    children?: {
      value: string,
      className: string,
      onClick?: () => void,
    }[]
  }[] = [
    {
      value: cart.hash,
      className: 'w3-col s1'
    },
    {
      value: cart.getModified(),
      className: 'w3-col s2'
    },
    {
      value: cart.email,
      className: 'w3-col s5'
    },
    {
      value: cart.totalWithTax(),
      className: 'w3-col s1'
    },
    {
      value: cart.GetCartStatus(),
      className: 'w3-col s1'
    },
    {
      className: 'w3-col s2 w3-row',
      children: [
        {
          value: '💡 Info',
          className: 'w3-col s12 w3-btn',
          onClick: () => {setStatus(status === Status.ready ? Status.more : Status.ready)}
        },
        {
          value: '🛒 Ausführen',
          className: `w3-col s12 w3-btn ${cart.HasSomeStatus([CartStatus.ISPAID]) ? 'w3-green' : ''} ${cart.HasSomeStatus([CartStatus.MARKEDASPAYED]) ? 'w3-yellow' : ''}`,
          onClick: async () => {
            const hash = cart.hash
            if (!window.confirm(`Willst Du diesen Warenkorb (${hash}) dem Kunden geben?`)) { return }
            await cart.markPaidAndFullfill()
            carts = carts.filter(c => c.hash !== hash)
            rerenderParent()
          }
        },
        {
          value: '🗑 Löschen',
          className: 'w3-col s12 w3-btn',
          onClick: async () => {
            const hash = cart.hash
            if(!window.confirm(`Willst Du wirklich den Warenkorb ${hash} löschen?`)) { return }
            await cart.trashCart()
            carts = carts.filter(c => c.hash !== hash)
            rerenderParent()
          }
        },
      ]
    },
  ]
  return <div className='w3-row w3-card w3-padding w3-margin'>
    {
      cols.map((col, index) => <div
          key={index}
          className={col.className}
          onClick={() => {
            if (col.onClick) {
              col.onClick()
            }
          }}
        >
          {col.value || ''}
          {
            col.children &&
            col.children.map((c, i) => <div
              key={`${index}-${i}`}
              className={c.className}
              onClick={() => {
                if (c.onClick) {
                  c.onClick()
                }
              }}
            >{c.value || ''}</div>)
          }
        </div>)
    }
    {
      status === Status.more &&
      <div className='w3-col s12'>
        <ul>
          {
          cart.books.map(b => <li
            key={b.id}
          >
            {b.id} - {b.name}
          </li>)
          }
        </ul>
      </div>
    }

  </div>
}