import React from 'react'
import mainservice from '../../services/MainService'
import CollectibleDTO, { CollectibleType } from '../../DTO/CollectibleDTO'
import CollectibleItemA from './CollectibleItemA'
import FileList, { FileListMode } from '../MediaManager/FileList'
import './CollectibleEditor.scss'

enum Status {
  init,
  editor,
  pictureSelector
}

type Props = {

}

type State = {
  svg: string,
  status: Status,
  pictureId: number
}

export default class CollectibleEditor extends React.Component<Props, State> {
  collectible: CollectibleDTO = new CollectibleDTO()
  constructor(props: Props) {
    super(props)
    this.state = {
      svg: '',
      status: Status.init,
      pictureId: -1
    }
  }

  componentDidMount() {
    // get data or create new data:
    const id: number = mainservice.nav.getVal('id') as number
    const type: CollectibleType = mainservice.nav.getVal('type') as CollectibleType
    this.collectible = new CollectibleDTO({id: id, type: type})
    this.collectible.getFromServer().then((resraw) => {
      if (!resraw) { return }
      const res = resraw as {id: number, status: string}
      if (res.status === 'redirect') {
        mainservice.navTo([['view', 'CollectibleEditor'], ['id', res.id], ['type', type]])
      }
      this.setState({status: Status.editor})
    })

  }
  closeFileList() {
    this.setState({status: Status.editor})
  }
  render() {
    return <div className="CollectibleEditor">
      <button
        onClick={() => mainservice.navTo([['view', 'CollectibleListA']])}
        style={{
          position: 'absolute',
          left: 0,
          top: 0
        }}
      >&larr;</button>
      {
        this.state.status === Status.editor &&
        <CollectibleItemA
          collectible={this.collectible}
          fresh={() => {}}
          editMode={true}
          selectFile={(o) => {
            this.setState({
              status: Status.pictureSelector
            })
          }}
          broadCastListenerKey='collectibleEditorChild'
        />
      }
      {
        this.state.status === Status.pictureSelector &&
        <FileList
          // parent={this}
          mode={FileListMode.selectPicture}
          parent={this}
          usePicture={async (id: number) => {
            await this.collectible.setPicture(id)
            this.setState({
              status: Status.editor,
              pictureId: id
            })
            mainservice.broadcast('collectibleEditorChild-newBG', this.collectible.getCssPicture())
          }}
        />
      }
    </div>
  }
}
