import React from 'react'

import './TextGeneratorTest.scss'
import Evaluation1TextGenerator from '../../Models/Evaluation1/TextGenerator'
import Testdata from './TextGeneratorTest.json'

type TestItem = {
  input: [number, number, number, number],
  wanted: string
}

type Props = {

}

type State = {

}

export default class TextGeneratorTest extends React.Component<Props, State> {

  textGenerator = new Evaluation1TextGenerator([0, 0, 0, 0])
  constructor(props: Props) {
    super(props)
  }
  makeTest(testItem: TestItem, index: number) {
    const input = testItem.input
    const wanted = testItem.wanted
    console.log('WANTED: ', wanted)
    const generatedItem = this.textGenerator.getItem(input) || {logic: 'ERROR'}
    const result = generatedItem.logic
    const color = (result === wanted) ? 'green' : 'red'
    console.log('result', result, color)
    return <tr key={`testResult-${index}`}>
      {
        input.map((i, j) => <td key={j}><code>{i}</code></td>)
      }
      <td><code>{wanted}</code></td>
      <td className={`w3-${color}`}><code>{result}</code></td>
    </tr>
  }
  render() {
    // Testdata.map((testItem: TestItem) => {return this.makeTest(testItem)})
    console.log('Testdata: ', Testdata)
    const testArr: TestItem[] = Testdata as TestItem[]
    return <div className="TestPage">
      <table className="TestTable">
        <thead>
          <tr>
            {
              ['s1', 's2', 's3', 's4', 'wanted', 'result'].map((s, i) => <td key={i}>{s}</td>)
            }
          </tr>
        </thead>
        <tbody>
          {
            testArr.map((item: TestItem, index: number) => this.makeTest(item, index))
          }
        </tbody>
      </table>
    </div>
  }
}
