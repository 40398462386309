import { useEffect, useState } from "react"
import { Plus, X } from "react-feather"
// import WidgetAdminDTO from "../../DTO/WidgetAdminDTO"
// import { WidgetTypes } from "../../DTO/WidgetDTO"
import WidgetsAdminDTO from "../../DTO/WidgetsAdminDTO"
import mainservice from "../../services/MainService"
// import InputA from "../InputA/InputA"
import Spinner from "../Spinner/Spinner"
import WidgetAdmin, { EditWidget, Enviroment } from "./WidgetAdmin"
import './widgetsAdmin.scss'

enum Status {
  loading,
  done,
  new,
}

export default function WidgetsAdmin () {
  const [status, setStatus] = useState(Status.loading)
  const [widgetId, setWidgetId] = useState(-1)
  const [widgets] = useState(new WidgetsAdminDTO())
  const load = () => {
    widgets.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }
  useEffect(load, [])
  const Content = () => {
    switch(status) {
      case Status.loading:
        return <Spinner />
      case Status.new:
        return <EditWidget success={load}/>
      case Status.done:
        return <>
          <button
            className='w3-button w3-green'
            onClick={() => {
              setStatus(Status.new)
            }}
          ><Plus /></button>
          {
            widgets.getAll().filter(w => (widgetId === -1 || w.id === widgetId)).map((widget) => <WidgetAdmin
              key={`widget-admin-${widget.id}`}
              widget={widget}
              enviroment={Enviroment.widgetAdmin}
              setWidget={() => {
                setWidgetId((widgetId === -1) ? widget.id : -1)
              }}
            />)
          }
        </>
    }
    
  }
  return <div
    className='w3-container widgetsadmin'
  >
    <button
      className='secondary-button'
      onClick={() => {
        mainservice.navTo([['view', 'adminlogin']])
      }}
    >&larr; Dashboard</button>
    {
      widgetId > -1 &&
      <button
        className={`w3-right`}
        onClick={() => {
          setWidgetId(-1)
        }}
      ><X /></button>
    }
    <h2>Widgets</h2>
    { Content() }
  </div>
}
